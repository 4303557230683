import React from 'react';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import GraphQL_API from '../../graphql';

const MatchCom = ({ m, func }) => {
  const router = useHistory();

  const deleteMatch = async (id) => {
    await func(id);
  };

  return (
    <div className="bg-[#0D1426] border border-gray-700 rounded">
      <div>
        <p className="px-2 text-gray-300 text-xs pt-2 text-center">
          <Moment format="MMM DD, YYYY">
            {new Date(m.attributes.match_date)}
          </Moment>{' '}
          <span>
            <span className="font-bold text-[#00AAFF]">
              {new Date(m.attributes.match_date).getHours() === 0
                ? 12
                : new Date(m.attributes.match_date).getHours() > 12
                ? new Date(m.attributes.match_date).getHours() - 12
                : new Date(m.attributes.match_date).getHours()}
              :
              {new Date(m.attributes.match_date).getMinutes().toString()
                .length > 1
                ? new Date(m.attributes.match_date).getMinutes()
                : `0${new Date(m.attributes.match_date).getMinutes()}`}{' '}
              {new Date(m.attributes.match_date).getHours() < 12 ? 'AM' : 'PM'}
            </span>{' '}
          </span>
        </p>
        <div className="flex p-2 pb-4">
          <div className="w-6/12 flex justify-end items-center">
            <p className="text-xs font-[500] truncate">
              {m.attributes.home_team.data.attributes.team_name}
            </p>
            <img
              className="w-6 h-6 mx-1"
              src={m.attributes.home_team.data.attributes.team_badge}
              alt=""
            />
          </div>
          <div className="w-6/12 flex justify-start items-center">
            <img
              className="w-6 h-6 mx-1"
              src={m.attributes.away_team.data.attributes.team_badge}
              alt=""
            />
            <p className="text-xs font-[500] truncate">
              {m.attributes.away_team.data.attributes.team_name}
            </p>
          </div>
        </div>
        <div className="bg-gradient-to-r from-gray-600 p-[2px] flex justify-between items-center">
          <div className="flex items-center">
            <img
              className="w-4 h-4"
              src={m.attributes.league.data.attributes.league_logo}
              alt=""
            />
            <p className="font-[600] text-xs mx-line-1">
              {m.attributes.league.data.attributes.league_name}
            </p>
          </div>
          <div className="flex">
            <AiFillEdit
              onClick={() => router.push(`/admin/football/update/${m.id}`)}
              className="mx-1 cursor-pointer opacity-60 hover:opacity-100"
            />
            <AiFillDelete
              onClick={() => deleteMatch(m.id)}
              className="mx-1 cursor-pointer text-rose-600 opacity-60 hover:opacity-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchCom;
