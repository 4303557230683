import { parseCookies, destroyCookie } from 'nookies';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const cookies = parseCookies();
const userFromCookies = cookies.auth_ft
  ? JSON.parse(cookies.auth_ft).user
  : null;

export const StateProvider = createContext();

const StateContext = ({ children }) => {
  const [user, setUser] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const router = useHistory();

  const signIn = (user) => {
    setUser(user);
  };

  const signOut = async () => {
    destroyCookie(null, 'auth_ft', { path: '/' });
    setUser(null);
  };

  useEffect(() => {
    setUser(userFromCookies);
  }, [userFromCookies]);

  return (
    <StateProvider.Provider
      value={{ user, signIn, signOut, openMenu, setOpenMenu }}
    >
      {children}
    </StateProvider.Provider>
  );
};

export const UseStateContext = () => useContext(StateProvider);
export default StateContext;
