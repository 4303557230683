import { gql } from '@apollo/client';

export const GET_COMMENTS = gql`
  query comments(
    $filters: CommentFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    comments(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          comment
          user {
            data {
              id
              attributes {
                username
                color
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation createComment($data: CommentInput!) {
    createComment(data: $data) {
      data {
        id
        attributes {
          comment
          user {
            data {
              id
              attributes {
                username
                color
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const SUB_NEW_COMMENT = gql`
  subscription newComment($mid: String) {
    newComment(mid: $mid) {
      name
      mid
      comment
      linkID
      color
      createdAt
    }
  }
`;
