import { gql } from '@apollo/client';

export const GET_BOXINGS = gql`
  query boxings(
    $filters: BoxingFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    boxings(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          title
          thumbnail
          post_type
          isLive
          link
          createdAt
          admin {
            data {
              id
              attributes {
                username
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

export const GET_BOXING_BY_ID = gql`
  query boxing($id: ID!) {
    boxing(id: $id) {
      data {
        id
        attributes {
          title
          thumbnail
          post_type
          isLive
          link
          createdAt
          admin {
            data {
              id
              attributes {
                username
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_BOXING = gql`
  mutation createBoxing($data: BoxingInput!) {
    createBoxing(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_BOXING = gql`
  mutation deleteBoxing($id: ID!) {
    deleteBoxing(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_BOXING = gql`
  mutation updateBoxing($id: ID!, $data: BoxingInput!) {
    updateBoxing(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;
