import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import GraphQL_API, { UserGQL } from '../graphql';
import nookies, { setCookie } from 'nookies';
import { UseStateContext } from '../context/StateContext';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const Register = () => {
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const [colorUser, setColorUser] = useState('#d10099');
  const { signIn } = UseStateContext();
  const location = useLocation();
  const redirect = location.search
    ? location.search.split('redirect=')[1]
    : '/';
  const [error, setError] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const _register = async (e) => {
    e.preventDefault();
    setError();
    const username = e.target.username.value.trim();
    const password = e.target.password.value;
    const rePassword = e.target.rePassword.value;

    if (username.length < 3) {
      setError({ message: 'បញ្ចូលឈ្មោះយ៉ាងតិច 3 ខ្ទង់' });
      return;
    }
    if (password.length < 6 || rePassword.length < 6) {
      setError({
        message:
          'ពាក្យសម្ងាត់ ឬផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់ត្រូវបញ្ចូលយ៉ាងតិច 6 ខ្ទង់',
      });
      return;
    }
    if (password !== rePassword) {
      setError({ message: 'ផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់មិនត្រឹមត្រូវ' });
      return;
    }
    try {
      const { data: registerUser } = await client.mutate({
        mutation: UserGQL.REGISTER,
        variables: {
          input: {
            username: username,
            email: `${uuidv4()}@test.com`,
            password: password,
          },
        },
      });

      const { data: updatedUser } = await client.mutate({
        mutation: UserGQL.UPDATE_USER,
        variables: {
          id: registerUser.register.user.id,
          data: {
            color: colorUser,
          },
        },
      });
      setCookie(
        null,
        'auth_ft',
        JSON.stringify({
          jwt: registerUser.register.jwt,
          user: updatedUser.updateUsersPermissionsUser.data,
        }),
        {
          maxAge: e.target.rememberMe.checked ? 30 * 24 * 60 * 60 : null,
          path: '/',
        }
      );
      signIn(updatedUser.updateUsersPermissionsUser.data);
      await router.push(redirect);
    } catch (error) {
      const err = error?.graphQLErrors[0]?.extensions?.error;
      if (err.message === 'Username are already taken') {
        setError({ message: 'ឈ្មោះធ្លាប់បានបង្កើតម្ដងរួចហើយ!' });
      }
      if (err.message === 'Email or Username are already taken') {
        setError({ message: 'ឈ្មោះធ្លាប់បានបង្កើតម្ដងរួចហើយ!' });
      }
      if (error.message === 'This attribute must be unique') {
        setError('ឈ្មោះធ្លាប់បានបង្កើតម្ដងរួចហើយ!');
        return;
      }
    }
  };

  console.log(error);
  return (
    <>
      <div className="khFont flex min-h-[800px] justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-[320px] space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#00AAFF]">
              ចុះឈ្មោះគណនីថ្មី
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600"></p>
          </div>
          <form
            className="mt-8 space-y-5"
            onSubmit={_register}
            autoComplete="off"
          >
            {error && error.message && (
              <p className="text-sm text-center text-rose-800 font-[500]">
                {error.message}
              </p>
            )}

            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  maxLength={20}
                  required
                  autoComplete="off"
                  className="bg-[#1E293B] text-[#00AAFF] font-bold relative block w-full appearance-none rounded-md border border-gray-600 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="ឈ្មោះ"
                />
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="bg-[#1E293B] text-[#00AAFF] font-bold relative mt-3 block w-full appearance-none rounded-none rounded-t-md border border-gray-600 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="ពាក្យសម្ងាត់យ៉ាងតិច 6ខ្ទង់"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  rePassword
                </label>
                <input
                  id="rePassword"
                  name="rePassword"
                  type="password"
                  required
                  className="bg-[#1E293B] text-[#00AAFF] font-bold relative block w-full appearance-none rounded-none rounded-b-md border border-gray-600 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="បញ្ជាក់ពាក្យសម្ងាត់"
                />
              </div>
            </div>

            <div className="flex justify-between ">
              <p className="font-bold text-sm m-0 text-gray-600">
                ជ្រើសរើសពណ៌:
              </p>
              <div className="flex">
                <div
                  onClick={() => setColorUser('#d10099')}
                  className={`rounded h-7 w-7 mx-[2px] bg-[#d10099] cursor-pointer ${
                    colorUser === '#d10099' &&
                    'border-2 scale-125 border-white drop-shadow mx-[8px]'
                  }`}
                ></div>
                <div
                  onClick={() => setColorUser('#4712d9')}
                  className={`rounded h-7 w-7 mx-[2px] bg-[#4712d9] cursor-pointer ${
                    colorUser === '#4712d9' &&
                    'border-2 scale-125 border-white drop-shadow  mx-[8px]'
                  }`}
                ></div>
                <div
                  onClick={() => setColorUser('#0eb286')}
                  className={`rounded h-7 w-7 mx-[2px] bg-[#0eb286] cursor-pointer ${
                    colorUser === '#0eb286' &&
                    'border-2 scale-125 border-white drop-shadow  mx-[8px]'
                  }`}
                ></div>
                <div
                  onClick={() => setColorUser('#aed100')}
                  className={`rounded h-7 w-7 mx-[2px] bg-[#aed100] cursor-pointer ${
                    colorUser === '#aed100' &&
                    'border-2 scale-125 border-white drop-shadow  mx-[8px]'
                  }`}
                ></div>
                <div
                  onClick={() => setColorUser('#b80009')}
                  className={`rounded h-7 w-7 mx-[2px] bg-[#b80009] cursor-pointer ${
                    colorUser === '#b80009' &&
                    'border-2 scale-125 border-white drop-shadow  mx-[8px]'
                  }`}
                ></div>
                <div
                  onClick={() => setColorUser('#ffa033')}
                  className={`rounded h-7 w-7 mx-[2px] bg-[#ffa033] cursor-pointer ${
                    colorUser === '#ffa033' &&
                    'border-2 scale-125 border-white drop-shadow  mx-[8px]'
                  }`}
                ></div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="rememberMe"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-600 font-bold cursor-pointer"
                >
                  រក្សាទុក ៣០ថ្ងៃ
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group font-bold relative flex w-full justify-center rounded-md border border-transparent bg-[#00AAFF] py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                ចុះឈ្មោះគណនីថ្មី
              </button>
              <div className="flex justify-between text-sm mt-2 text-gray-600 font-bold">
                <p>មានគណនីរួចរាល់ហើយ?</p>
                <p
                  className="cursor-pointer underline text-base text-rose-600 font-[600]"
                  onClick={() =>
                    router.push(`/auth/login?redirect=${redirect}`)
                  }
                >
                  ចូលគណនី
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const getServerSideProps = async (ctx) => {
  const cookies = nookies.get(ctx);
  if (cookies.auth) return { redirect: { destination: '/' } };
  return {
    props: {},
  };
};

export default Register;
