import React from 'react';
import { BsSquareFill } from 'react-icons/bs';
import Round16 from '../components/Round16';

const LeagueStand = ({ season }) => {
  return (
    <>
      {season && season.attributes.table_type === 'League' && (
        <>
          <div className="mt-1 overflow-x-auto w22Scroll">
            <div className="min-w-[450px] rounded-lg">
              <div className="p-1 bg-gradient-to-r from-[#0C304F] rounded-t-lg border-b border-gray-600">
                <p className="md:text-center khFont font-bold text-[#00AAFF]">
                  តារាងចំណាត់ថ្នាក់
                </p>
              </div>

              <div className="pr-1 mb-1 bg-[#1E293B] rounded-b-lg overflow-hidden">
                <table className="w-full table-auto border-collapse">
                  <thead>
                    <tr className="text-gray-400 text-center border-b border-gray-700">
                      <td className="khFont text-start pl-3">ក្រុម</td>
                      <td className="w-6 text-xs">MP</td>
                      <td className="w-6 text-xs">W</td>
                      <td className="w-6 text-xs">D</td>
                      <td className="w-6 text-xs">L</td>
                      <td className="w-6 text-xs">GF</td>
                      <td className="w-6 text-xs">GA</td>
                      <td className="w-6 text-xs">GD</td>
                      <td className="w-6 text-xs">Pts</td>
                      <td className="w-20">Last 5</td>
                    </tr>
                  </thead>
                  <tbody>
                    {season &&
                      season.attributes.TeamInfo.map((t, i) => (
                        <tr
                          key={t.id}
                          className={`text-sm text-center text-gray-400  ${
                            i === season.attributes.TeamInfo.length - 1
                              ? ''
                              : 'border-b border-gray-700'
                          }`}
                        >
                          <td
                            className={`p-[5px] border-l-4 ${
                              i < 4
                                ? 'border-[blue]'
                                : i === 4
                                ? 'border-[orange]'
                                : i > 16
                                ? 'border-[red]'
                                : 'border-transparent'
                            } `}
                          >
                            <span className="flex">
                              {i + 1}
                              <span>
                                <img
                                  className="w-5 mx-2"
                                  src={t.team.data.attributes.team_badge}
                                  alt=""
                                />
                              </span>
                              <span className="text font-[500]">
                                {t.team.data.attributes.team_name}
                              </span>
                            </span>
                          </td>
                          <td className="text-xs">{t.MP}</td>
                          <td className="text-xs">{t.W}</td>
                          <td className="text-xs">{t.D}</td>
                          <td className="text-xs">{t.L}</td>
                          <td className="text-xs">{t.GF}</td>
                          <td className="text-xs">{t.GA}</td>
                          <td className="text-xs">{t.GF - t.GA}</td>
                          <td className="font-bold text-rose-600 text-base">
                            {t.Pts}
                          </td>
                          <td className="flex flex-row items-center justify-center">
                            {t.last5.map((st, j) => {
                              if (st === 'w')
                                return (
                                  <span
                                    key={st + j}
                                    className="text-xs rounded-full bg-[green] p-[2px] px-[3px] mx-[2px]"
                                  >
                                    W
                                  </span>
                                );
                              if (st === 'd')
                                return (
                                  <span
                                    key={st + j}
                                    className="text-xs rounded-full bg-[gray] p-[2px] px-[5px] mx-[2px]"
                                  >
                                    D
                                  </span>
                                );
                              if (st === 'l')
                                return (
                                  <span
                                    key={st + j}
                                    className="text-xs rounded-full bg-rose-600 p-[2px] px-[5px] mx-[2px]"
                                  >
                                    L
                                  </span>
                                );
                              if (st === '-')
                                return (
                                  <span
                                    key={st + j}
                                    className="text-xs rounded-full bg-gray-200 text-black mt-2 px-[5px] mx-[2px] font-bold"
                                  >
                                    -
                                  </span>
                                );
                            })}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 bg-[#1E293B] rounded-md mb-2 p-2 mt-2  text-sm">
            <div className="ml-2">
              <p className="font-bold text-rose-600">
                Qualification/Relegation
              </p>
              {season &&
                season.attributes.status.map((s) => (
                  <div className="flex" key={s.color}>
                    <BsSquareFill
                      className={`my-2 mr-4`}
                      color={s.color}
                      size={10}
                    />
                    <p className="truncate">{s.type}</p>
                  </div>
                ))}
            </div>
            <div className="ml-2">
              <p className="font-bold text-rose-600">៥ ប្រកួតចុងក្រោយ</p>
              <div>
                <span className="text-[10px] font-bold rounded-full bg-[green] p-[2px] px-[3px] mx-[1px] mr-3">
                  W
                </span>
                <span className="text-xs">ឈ្នះ</span>
              </div>
              <div>
                <span className="text-[10px] font-bold rounded-full bg-[gray] p-[2px] px-[5px] mx-[1px] mr-[10px]">
                  D
                </span>
                <span className="text-xs">ស្មើ</span>
              </div>
              <div>
                <span className="text-[10px] font-bold rounded-full bg-rose-600 p-[2px] px-[5px] mx-[1px] mr-3">
                  L
                </span>
                <span className="text-xs">ចាញ់</span>
              </div>
            </div>
          </div>
        </>
      )}
      {season && season.attributes.table_type === 'Group' && (
        <>
          <div className="mt-3">
            <p className="font-bold">Groups</p>
            <div className="grid md:grid-cols-2 gap-2">
              {season.attributes.GroupState.map((G) => (
                <div
                  key={G.Group}
                  className="rounded-md overflow-hidden border border-gray-600"
                >
                  <p className="bg-[#1E293B] p-1 text-center font-bold text-[#00AAFF] text-sm">
                    Group {G.Group}
                  </p>
                  <div className="flex justify-between px-2 text-sm border-b border-gray-800 pt-1">
                    <p>ក្រុម</p>
                    <div className="flex text-center text-xs">
                      <div className="w-5 text-xs ">MP</div>
                      <div className="w-5 text-xs ">W</div>
                      <div className="w-5 text-xs ">D</div>
                      <div className="w-5 text-xs ">L</div>
                      <div className="w-5 text-xs ">GA</div>
                      <div className="w-5 text-xs text-[#00AAFF]">Pts</div>
                    </div>
                  </div>
                  {G.teams.map((T) => (
                    <div
                      key={T.id + 'team'}
                      className="pr-2 py-1 flex justify-between items-center border-b border-gray-800"
                    >
                      <div
                        className={`flex items-center border-l-4 border-${
                          T.color ? `[${T.color}]` : 'transparent'
                        } pl-2 p-[2px]`}
                      >
                        <img
                          className="w-5 h-5 mr-1"
                          src={T.team.data.attributes.team_badge}
                          alt=""
                        />
                        <span className="text-xs font-[500] truncate">
                          {T.team.data.attributes.team_name}
                        </span>
                      </div>
                      <div className="flex text-center text-gray-400 text-sm">
                        <div className="w-5 text-xs">{T.MP}</div>
                        <div className="w-5 text-xs">{T.W}</div>
                        <div className="w-5 text-xs">{T.D}</div>
                        <div className="w-5 text-xs">{T.L}</div>
                        <div className="w-5 text-xs">{T.GA}</div>
                        <div className="w-5 text-xs text-[#00AAFF] font-bold">
                          {T.Pts}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="mt-3">
            <Round16 Round={season.attributes.Round} />
          </div>
        </>
      )}
    </>
  );
};

export default LeagueStand;
