import React from 'react';

const Pagination = ({ pageCount, page, func, className = 'mx-auto' }) => {
  const loop = () => {
    var foo = [];
    for (var i = 1; i <= pageCount; i++) {
      foo.push(i);
    }
    return (
      <div className="cursor-pointer">
        {page > 2 && (
          <span onClick={() => func(1)} className={`px-1`}>
            1
          </span>
        )}
        {page > 3 && <span className="text-gray-500">...</span>}
        {foo.map((p) => {
          return (
            <span
              onClick={() => func(p)}
              key={'page' + p}
              className={`${page < p - 1 && 'hidden'} ${
                page > p + 1 && 'hidden'
              } px-1 rounded ${
                page === p &&
                'rounded border border-gray-600 text-[#00AAFF] font-bold'
              }`}
            >
              {p}
            </span>
          );
        })}
        {page < pageCount - 2 && <span className="text-gray-500">...</span>}
        {page < pageCount - 1 && (
          <span onClick={() => func(pageCount)} className="px-1">
            {pageCount}
          </span>
        )}
      </div>
    );
  };
  return (
    <div
      className={`p-2 flex justify-between max-w-[350px] select-none ${className}`}
    >
      <div
        onClick={() => {
          if (page !== 1) func(page - 1);
        }}
        className={`text-[#00AAFF] font-[500] opacity-50 ${
          page !== 1 && 'opacity-100 cursor-pointer'
        }`}
      >
        Prev
      </div>
      {loop()}
      <div
        onClick={() => {
          if (page !== pageCount) func(page + 1);
        }}
        className={`text-[#00AAFF] font-[500] opacity-50 ${
          page !== pageCount && 'opacity-100 cursor-pointer'
        }`}
      >
        Next
      </div>
    </div>
  );
};

export default Pagination;
