import React from 'react';
import { useLocation, Route, Redirect } from 'react-router-dom';
import { UseStateContext } from '../context/StateContext';
import { parseCookies } from 'nookies';
import { isTrue } from '../functions';

const DynamicRoute = (props) => {
  const location = useLocation();
  const { user } = UseStateContext();
  const cookies = parseCookies();

  if (props.auth && !user) {
    return (
      <Redirect
        to={`/auth/login?redirect=${location.pathname + location.search}`}
      />
    );
  } else if (props.noAuth && user) {
    return <Redirect to="/" />;
  } else if (props.admin && !isTrue(cookies, 'Admin')) {
    return <Redirect to="/" />;
  } else {
    return <Route component={props.component} {...props} />;
  }
};

export default DynamicRoute;
