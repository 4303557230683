import React, { useEffect, useState } from 'react';
import Player from '../components/Player';
import GraphQL_API, { MatchGQL } from '../graphql';
import Hls from 'hls.js';
import { BsFillEyeFill } from 'react-icons/bs';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';
const socket = io(process.env.REACT_APP_COUNT_VIEW_API, {
  transports: ['websocket'],
});

const Iframe = () => {
  const client = GraphQL_API({ ctx: null });
  const location = useLocation();
  const { mid } = useParams();
  const query = qs.parse(location.search);
  const linkId = query.linkId;
  const [currentLink, setCurrentLink] = useState();
  const [views, setViewer] = useState(0);
  const [match, setMatch] = useState();

  useEffect(() => {
    if (socket) {
      if (!match) return;
      socket.connect();
      socket.emit('join-room', `football${match.id}`);
      socket.on(`user-watching-football${match.id}`, (res) => {
        setViewer(res);
      });
    }
    return () => socket.disconnect();
  }, [socket, match]);

  useEffect(() => {
    fetchData();
  }, [linkId]);

  const fetchData = async () => {
    const { data } = await client.query({
      query: MatchGQL.GET_MATCH_BY_ID,
      variables: { id: mid },
    });
    if (data) {
      setMatch(data.match?.data);
      setCurrentLink(
        data.match?.data.attributes.link.find((l) => l.id === linkId)
      );
    }
  };

  useEffect(() => {
    link3(currentLink);
  }, [currentLink]);

  const link3 = async () => {
    if (currentLink?.link_type !== 'Live') return;
    const { data: m3u8link } = await axios.get('https://net.learnwith21.com');
    var video = document.getElementById('videoElement');
    video.addEventListener('dblclick', () => fullscreen());
    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = `${m3u8link}/live/${currentLink?.link_url}/index.m3u8`;
    } else if (Hls.isSupported()) {
      var hls = new Hls();
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !');
      });
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
          'manifest loaded, found ' + data.levels.length + ' quality level'
        );
      });
      hls.loadSource(`${m3u8link}/live/${currentLink?.link_url}/index.m3u8`);
      // bind them together
      hls.attachMedia(video);
    }
  };

  useEffect(() => {
    if (currentLink?.link_type !== 'm3u8') return;
    var video = document.getElementById('videoElement');
    video.addEventListener('dblclick', () => fullscreen());
    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = currentLink?.link_url;
    } else if (Hls.isSupported()) {
      var hls = new Hls();
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !');
      });
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
          'manifest loaded, found ' + data.levels.length + ' quality level'
        );
      });
      hls.loadSource(currentLink?.link_url);
      // bind them together
      hls.attachMedia(video);
    }
  }, [currentLink]);

  const fullscreen = () => {
    const divObj = document.getElementById('wrapper');

    if (
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (divObj.msRequestFullscreen) {
        divObj.msRequestFullscreen();
      } else if (divObj.mozRequestFullScreen) {
        divObj.mozRequestFullScreen();
      } else if (divObj.webkitRequestFullscreen) {
        divObj.webkitRequestFullscreen();
      } else {
        console.log('Fullscreen API is not supported');
      }
    } else {
      if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <>
      <div className="mx-auto">
        <div>
          {currentLink?.link_type === 'embed' && (
            <Player url={currentLink?.link_url} />
          )}
          {currentLink?.link_type === 'Live' && (
            <>
              <div className="wrapper flex relative" id="wrapper">
                <video
                  id="videoElement"
                  className="bg-black"
                  height="100%"
                  width="100%"
                  controls
                  autoPlay
                  controlsList="noplaybackrate nodownload"
                  disablePictureInPicture
                  muted
                ></video>
                <div
                  className="absolute top-[10px] left-[10px] flex"
                  style={{ zIndex: 1000 }}
                >
                  <span className="bg-red-700 text-white px-2 rounded text-sm mr-1">
                    LIVE
                  </span>
                  {views && (
                    <span
                      className="text-white px-2 rounded text-sm flex"
                      style={{ background: 'rgba(0,0,0,.5)' }}
                    >
                      <BsFillEyeFill className="mt-[3px] mr-1" />{' '}
                      <span className="mt-[1px] khFont">{views}</span>
                    </span>
                  )}
                </div>
                <div
                  onClick={() => window.open('https://www.sportliveft.com')}
                  className={`absolute top-[6px] flex bg-white p-1 cursor-pointer rounded shadow hover:underline hover:text-[blue]`}
                  style={{ zIndex: 1000, left: views ? '105px' : '60px' }}
                >
                  <img width={20} src="/sportliveft.png" alt="" />
                  <p className="font-[600] ml-1">www.sportliveft.com</p>
                </div>
              </div>
            </>
          )}
          {currentLink?.link_type === 'm3u8' && (
            <>
              <div className="wrapper flex relative" id="wrapper">
                <video
                  id="videoElement"
                  className="bg-black"
                  height="100%"
                  width="100%"
                  controls
                  autoPlay
                  controlsList="noplaybackrate nodownload"
                  disablePictureInPicture
                  muted
                ></video>
                <div
                  className="absolute top-[10px] left-[10px] flex"
                  style={{ zIndex: 1000 }}
                >
                  <span className="bg-red-700 text-white px-2 rounded text-sm mr-1">
                    LIVE
                  </span>
                  {views && (
                    <span
                      className="text-white px-2 rounded text-sm flex"
                      style={{ background: 'rgba(0,0,0,.5)' }}
                    >
                      <BsFillEyeFill className="mt-[3px] mr-1" />{' '}
                      <span className="mt-[1px] khFont">{views}</span>
                    </span>
                  )}
                </div>
                <div
                  onClick={() =>
                    (window.location.href = 'https://www.sportliveft.com')
                  }
                  className={`absolute top-[6px] flex bg-white p-1 cursor-pointer rounded shadow hover:underline hover:text-[blue]`}
                  style={{ zIndex: 1000, left: views ? '105px' : '60px' }}
                >
                  <img width={20} src="/sportliveft.png" alt="" />
                  <p className="font-[600] ml-1">www.sportliveft.com</p>
                </div>
              </div>
            </>
          )}
          {currentLink?.link_type === 'Twitch' && (
            <div className="">
              <div className="player-wrapper">
                <iframe
                  id="twitchIframe"
                  className="react-player drop-shadow"
                  src={`https://player.twitch.tv/?channel=${currentLink?.link_url}&parent=${process.env.DOMAIN}`}
                  frameBorder="0"
                  allowFullScreen
                  scrolling="no"
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Iframe;
