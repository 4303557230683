import React, { useEffect, useState } from 'react';
import GraphQL_API, { BoxingGQL } from '../graphql';
import Moment from 'react-moment';
import { LinkItUrl } from 'react-linkify-it';
import { BsFillEyeFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import JWPlayer from '@jwplayer/jwplayer-react';
import Comment from '../components/Comment';
import { io } from 'socket.io-client';
import axios from 'axios';
import SideBanner from '../components/SideBanner';

// const socket = io(process.env.REACT_APP_COUNT_VIEW_API, {
//   reconnection: true,
//   //   reconnectionDelay: 1000,
//   //   reconnectionDelayMax: 5000,
//   reconnectionAttempts: 3,
//   transports: ['websocket'],
// });

const socket = io.connect(process.env.REACT_APP_COUNT_VIEW_API, {
  reconnect: true,
  transports: ['websocket'],
});

// const socket = io('http://192.168.1.101:8101', {
//   reconnection: true,
//   //   reconnectionDelay: 1000,
//   //   reconnectionDelayMax: 5000,
//   reconnectionAttempts: 3,
//   transports: ['websocket'],
// });

const BoxingById = () => {
  const client = GraphQL_API({ ctx: null });
  const { bid } = useParams();
  const [comments, setComments] = useState([]);
  const [viewer, setViewer] = useState(0);
  const [boxing, setBoxing] = useState(null);
  const [liveLink, setLiveLink] = useState('');

  useEffect(() => {
    if (socket) {
      if (!boxing) return;
      if (boxing.attributes.post_type !== 'live') return;
      socket.connect();
      socket.emit('join-room', `boxing${bid}`);
      socket.on(`user-watching-boxing${bid}`, (res) => {
        setViewer(res);
      });

      socket.on(`comment-room-boxing${bid}`, (comment) => {
        addComment(comment.comment);
      });
    }
    return () => {
      //   socket.emit('leave-room', `football${match.id}`);
      socket.disconnect();
    };
  }, [boxing]);

  useEffect(() => {
    link3(boxing);
  }, [boxing]);

  const link3 = async (boxing) => {
    if (boxing?.attributes.post_type !== 'live') return;
    const { data: m3u8link } = await axios.get('https://net.learnwith21.com');
    if (m3u8link) {
      setLiveLink(`${m3u8link}/live/${boxing.attributes.link}/index.m3u8`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data: boxing } = await client.query({
      query: BoxingGQL.GET_BOXING_BY_ID,
      variables: { id: bid },
    });
    if (boxing) {
      setBoxing(boxing.boxing.data);
    }
  };

  const _createCommet = async (e) => {
    const comment = {
      comment: { ...e },
      room: `boxing${bid}`,
    };
    socket.emit('comment', comment);
  };

  const addComment = async (comment) => {
    setComments((c) => {
      if (c.includes(comment)) return c;
      return [...c, comment];
    });
  };

  return (
    <div className="flex justify-around text-gray-100">
      <div className="hidden lg:block w-full max-w-[250px] xl:max-w-[300px] p-1">
        <div className="sticky top-[92px]">
          <SideBanner />
        </div>
      </div>
      <div className="w-full max-w-[600px] p-1">
        {boxing && (
          <div className="rounded-xl sm:p-1 sm:bg-[#1E293B] shadow mb-2">
            <div className="relative min-h-[150px] bg-black sm:rounded-t-lg overflow-hidden">
              {boxing.attributes.post_type === 'live' && liveLink && (
                <JWPlayer
                  className=""
                  autostart={true}
                  file={liveLink}
                  library="https://content.jwplatform.com/libraries/LJ361JYj.js"
                />
              )}

              {boxing.attributes.post_type === 'iframe' && (
                <div className="player-wrapper">
                  <iframe
                    className="react-player drop-shadow"
                    src={boxing.attributes.link}
                    allowFullScreen
                    allow="autoplay;"
                    height="100%"
                    width="100%"
                    frameBorder={1}
                    scrolling="no"
                  ></iframe>
                </div>
              )}

              {boxing?.attributes.isLive &&
                boxing?.attributes.post_type === 'live' && (
                  <>
                    <div className="absolute bg-rose-600 text-white rounded shadow text-sm font-[600] px-1 top-2 left-2">
                      LIVE
                    </div>
                    <div
                      style={{ background: 'rgba(0,0,0,.5)' }}
                      className="flex absolute text-white rounded shadow text-sm font-[600] px-1 top-2 left-12"
                    >
                      <BsFillEyeFill className="mt-[3px] mr-1" />{' '}
                      <span className="mt-[1px] khFont">{viewer}</span>
                    </div>
                  </>
                )}
            </div>
            <div className="text-gray-300 p-2">
              <p className="font-[600] mb-2">
                <LinkItUrl className="text-[#00AAFF] hover:underline">
                  {boxing.attributes.title}
                </LinkItUrl>
              </p>
              <div className="flex">
                <img
                  className="w-10 h-10 rounded-full mr-3"
                  src="/user2.png"
                  alt=""
                />
                <div>
                  <p>
                    <span className="font-bold text-[#00AAFF]">
                      {boxing?.attributes.admin.data.attributes.username}
                    </span>{' '}
                    {boxing?.attributes.isLive && (
                      <span className="text-xs">
                        ផ្សាយបន្ដផ្ទាល់{' '}
                        <span className="text-base font-bold text-rose-600">
                          កីឡាប្រដាល់
                        </span>
                      </span>
                    )}
                  </p>
                  <p className="text-xs">
                    <Moment fromNow>{boxing.attributes.createdAt}</Moment>
                  </p>
                </div>
              </div>
            </div>
            {boxing.attributes.post_type === 'live' && (
              <div className="p-1">
                <p className="font-[600] text-[#00AAFF] mb-1">Comments</p>
                <div className="bg-[#0F172A] rounded">
                  <Comment
                    HC={500}
                    _createCommet={_createCommet}
                    comments={comments}
                    mid={`boxing${bid}`}
                    id={bid}
                    sport={'boxing'}
                    setComments={setComments}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="hidden md:block w-full max-w-[250px] xl:max-w-[300px] p-1">
        <div className="sticky top-[92px]">
          <SideBanner />
        </div>
      </div>
    </div>
  );
};

export default BoxingById;
