import { createContext, useContext, useEffect, useState } from 'react';

export const TimeProvider = createContext();

const TimeContext = ({ children }) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const fetchCurrentTime = () => {
    setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);
  };

  useEffect(() => {
    fetchCurrentTime();
  }, []);
  return (
    <TimeProvider.Provider value={{ currentTime }}>
      {children}
    </TimeProvider.Provider>
  );
};

export const UseTimeContext = () => useContext(TimeProvider);
export default TimeContext;
