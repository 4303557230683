import React, { useState } from 'react';

const PopupDetails = ({ details }) => {
  const [open, setOpen] = useState(false);

  const close = () => {
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  return (
    <div className="max-w-[70px]">
      <button
        onClick={() => setOpen(true)}
        title="វិភាគ"
        className="cursor-pointer mx-1 bg-blue-600 hover:bg-blue-500 text-xs px-1 text-white rounded-sm"
      >
        វិភាគ
      </button>
      <div
        className={`absolute w-full ${
          open ? 'top-0 bottom-0' : 'top-[7rem]'
        } left-0  ease-in-out duration-200`}
        onClick={close}
        style={{
          backdropFilter: 'blur(5px)',
        }}
      >
        <div className="flex justify-center items-center">
          <div className="bg-white p-2 drop-shadow border border-gray-800 w-full cursor-pointer">
            <p className="text-center h-[100px] text-sm font-[500]">
              {details || 'មិនមានការវិភាគ'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDetails;
