import React, { useState } from 'react';

const Popup = ({ text, icon, del = false, func, position = 50 }) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  const accept = async () => {
    await func();
    close();
  };
  return (
    <div className="relative max-w-[70px] flex">
      <button onClick={() => setOpen(true)} onBlur={() => setOpen(false)}>
        {icon}
        <div
          className="absolute bottom-[20px] rounded bg-[#1E293B] p-2 w-[150px] border border-gray-600 drop-shadow-xl"
          style={{
            display: open ? 'block' : 'none',
            transform: `translate(-${position}%,0)`,
          }}
        >
          <p>{text}</p>
          <div className="flex flex-row justify-center">
            <p
              className={`px-2 text-sm rounded shadow bg-${
                del ? 'rose-800 text-white' : '[#00AAFF]'
              }`}
              onClick={accept}
            >
              យល់ព្រម
            </p>
            <p
              className="px-2 text-sm border shadow ml-1 rounded bg-gray-200 text-black"
              onClick={close}
            >
              បោះបង់
            </p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default Popup;
