import React, { useEffect, useState } from 'react';

const Player = ({
  url = 'https://www.bbvplayline2a.com/video4.html?url=https://hls1.yuanzhi.org.cn/live/H1056142.m3u8?txSecret=738506f3eb2d3737f89831112ac14e13&txTime=634ECE73&_s=stream',
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <div className="player-wrapper">
      {show && (
        <iframe
          className="react-player bg-black drop-shadow"
          src={url}
          //   sandbox="allow-scripts allow-same-origin"
          allow="encrypted-media"
          height="100%"
          width="100%"
          controls
          allowFullScreen={true}
        ></iframe>
      )}
    </div>
  );
};

export default Player;
