import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../components/Pagination';
import GraphQL_API, { MatchGQL } from '../graphql';
import AdminLayout from './com/AdminLayout';
import MatchCom from './com/MatchCom';

const FootballScreen = () => {
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const [matches, setMatches] = useState([]);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = async (p) => {
    const { data: matches } = await client.query({
      query: MatchGQL.GET_MATCHES,
      variables: {
        sort: ['match_date:desc'],
        pagination: {
          pageSize: 28,
          page: p,
        },
      },
    });

    if (matches) {
      setMatches(matches.matches.data);
      setPagination(matches.matches.meta.pagination);
    }
  };

  const delMatch = async (id) => {
    if (window.confirm(`Delete?`) === true) {
      const { data } = await client.mutate({
        mutation: MatchGQL.DELETE_MATCH,
        variables: { id: id },
      });
      if (data) {
        fetchData(pagination.page);
      }
    }
  };

  return (
    <AdminLayout>
      <div className="sticky top-[57px] bg-[#0F172A] p-2 flex justify-between z-10 shadow">
        <p className="font-bold">⚽ កីឡាបាល់ទាត់</p>
        <button
          onClick={() => router.push(`/admin/football/create`)}
          className="bg-rose-600 rounded hover:bg-sky-400 text-black font-bold px-3"
        >
          បង្កើតថ្មី
        </button>
      </div>
      <div className="p-2">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
          {matches.map((m) => (
            <MatchCom key={m.id} m={m} func={(id) => delMatch(id)} />
          ))}
        </div>
        <div className="">
          {pagination && (
            <Pagination
              pageCount={pagination.pageCount}
              page={pagination.page}
              func={(p) => fetchData(p)}
              className={'mx-auto'}
            />
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default FootballScreen;
