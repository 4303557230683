import React, { useEffect, useState } from 'react';
import AdminLayout from './com/AdminLayout';
import {
  AiFillEdit,
  AiFillDelete,
  AiFillEye,
  AiOutlineLink,
} from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import GraphQL_API, { NewsGQL } from '../graphql';
import Popup from '../components/Popup';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const NewsScreen = () => {
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const [newss, setNewss] = useState([]);
  const [meta, setMeta] = useState();

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);
  const fetchData = async ({ page = 1 }) => {
    const { data } = await client.query({
      query: NewsGQL.GET_SPORTNEWSS,
      variables: {
        sort: ['createdAt:desc'],
        pagination: {
          page: page,
          pageSize: 18,
        },
      },
    });
    if (data) {
      setMeta(data.sportNewss.meta);
      if (page === 1) {
        setNewss(data.sportNewss.data);
        return;
      }
      setNewss((n) => {
        return [...n, ...data.sportNewss.data];
      });
    }
  };

  const deleteNews = async (id) => {
    const { data } = await client.mutate({
      mutation: NewsGQL.DELETE_NEWS,
      variables: {
        id: id,
      },
    });
    if (data) {
      setNewss((ns) => {
        const newNs = ns.filter((n) => n.id !== id);
        return newNs;
      });
      setMeta((m) => {
        return { ...m, pagination: {} };
      });
    }
  };

  return (
    <AdminLayout>
      <div className="p-1">
        <div className="flex justify-between">
          <p className="font-bold">ព័ត៌មានកីឡា</p>
          <button
            className="bg-[#00AAFF] hover:bg-sky-400 text-black font-bold px-3"
            onClick={() => router.push('/admin/news/create')}
          >
            បង្កើតថ្មី
          </button>
        </div>
        <table className="table-auto w-full mt-2 border-collapse border border-slate-700 min-w-[700px]">
          <thead>
            <tr className="bg-slate-300 text-gray-900">
              <th className="w-9 py-2 ">ល.រ</th>
              <th className="w-[100px]">រូបតំណាង</th>
              <th className="text-start">ចំណងជើង</th>
              <th className="w-[150px] text-start">អ្នកបង្កើត</th>
              <th className="w-[170px]">កំណត់</th>
            </tr>
          </thead>
          <tbody>
            {newss.map((n, i) => (
              <tr className="bg-[#0D1426] border border-slate-800" key={n.id}>
                <td className="text-center">{i + 1}</td>
                <td>
                  <img
                    className="w-16 h-10 mx-auto"
                    src={n.attributes.thumnail}
                    alt=""
                    style={{ objectFit: 'cover' }}
                  />
                </td>
                <td className="">{n.attributes.title}</td>
                <td>
                  {n.attributes.users_permissions_user.data.attributes.username}
                </td>
                <td>
                  <div className="flex justify-center items-center">
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_DOMAIN}/news/${n.id}`}
                      onCopy={() => console.log(`copy ${n.id}`)}
                    >
                      <AiOutlineLink
                        title="លីង"
                        className="cursor-pointer mr-4"
                      />
                    </CopyToClipboard>
                    <AiFillEye
                      title="មើល"
                      className="cursor-pointer"
                      onClick={() => router.push(`/news/${n.id}`)}
                    />
                    <AiFillEdit
                      title="កែ"
                      className="cursor-pointer mx-4"
                      onClick={() => router.push(`/admin/news/update/${n.id}`)}
                    />
                    <Popup
                      icon={
                        <AiFillDelete
                          title="លុប"
                          className="cursor-pointer text-rose-600"
                        />
                      }
                      text="មានេះវីដេអូមានេះវីដេអូ"
                      position={80}
                      del
                      func={() => deleteNews(n.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className=" mt-2">
          {meta && meta.pagination.page < meta.pagination.pageCount && (
            <button
              className="bg-slate-300 rounded hover:bg-sky-400 text-black font-bold px-3 mr-2"
              onClick={() => fetchData({ page: meta.pagination.page + 1 })}
            >
              មានទៀត
            </button>
          )}
          {/* <span>
            សរុប៖{' '}
            <span className="font-bold text-rose-600">
              {meta && meta.pagination.total}
            </span>{' '}
            ព័ត៌មាន
          </span> */}
        </div>
      </div>
    </AdminLayout>
  );
};

export default NewsScreen;
