import { gql } from '@apollo/client';

export const GET_MATCH_BY_ID = gql`
  query get_match_by_id($id: ID!) {
    match(id: $id) {
      data {
        id
        attributes {
          country {
            data {
              id
              attributes {
                country_name
                country_logo
              }
            }
          }
          league {
            data {
              id
              attributes {
                league_name
                league_logo
              }
            }
          }
          home_team {
            data {
              id
              attributes {
                team_name
                team_badge
              }
            }
          }
          away_team {
            data {
              id
              attributes {
                team_name
                team_badge
              }
            }
          }
          match_date
          link {
            id
            link_name
            link_type
            link_url
          }
        }
      }
    }
  }
`;

export const GET_MATCHES = gql`
  query matches(
    $filters: MatchFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    matches(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          analysis
          result {
            id
            home_gold
            away_gold
            won
          }
          league {
            data {
              id
              attributes {
                league_name
                league_logo
              }
            }
          }
          home_team {
            data {
              id
              attributes {
                team_name
                team_badge
              }
            }
          }
          away_team {
            data {
              id
              attributes {
                team_name
                team_badge
              }
            }
          }
          match_date
          link {
            id
            link_name
            link_type
            link_url
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

export const CREATE_MATCH = gql`
  mutation createMatch($data: MatchInput!) {
    createMatch(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_MATCH = gql`
  mutation updateMatch($id: ID!, $data: MatchInput!) {
    updateMatch(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_MATCH = gql`
  mutation deleteMatch($id: ID!) {
    deleteMatch(id: $id) {
      data {
        id
      }
    }
  }
`;
