import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ParserHTML from 'html-react-parser';
import GraphQL_API, { NewsGQL } from '../graphql';

const SportNewsById = () => {
  const client = GraphQL_API({ ctx: null });
  const { nid } = useParams();
  const [news, setNews] = useState();
  useEffect(() => {
    fetchData(nid);
  }, [nid]);
  const fetchData = async (id) => {
    try {
      const { data } = await client.query({
        query: NewsGQL.GET_SPORTNEWS_BY_ID,
        variables: { id: id },
      });
      setNews(data.sportNews.data);
    } catch (error) {}
  };
  return (
    <div className="max-w-[750px] text-gray-300 mx-auto mb-5 px-2 md:px-0">
      {news && (
        <>
          <div className="overflow-hidden drop-shadow-xl cursor-pointer">
            <div className="text-start khFont">
              <p className="text-[1.5rem] my-2 font-bold">
                {news.attributes.title}
              </p>
              <div className="my-2 flex justify-between items-center">
                <div className="flex justify-center items-center">
                  <img className="w-11 h-11" src="/user2.png" alt="" />
                  <div className="ml-2">
                    <p className="text-[#00AAFF] font-bold">
                      {
                        news.attributes.users_permissions_user.data.attributes
                          .username
                      }
                    </p>
                    <div className="opacity-50 text-xs">
                      <span className="mr-3 text-gray-300">
                        {new Date(news.attributes.createdAt).toDateString()}
                      </span>
                      <span className="text-xs">
                        {new Date(news.attributes.createdAt).getHours()}:
                        {new Date(news.attributes.createdAt)
                          .getMinutes()
                          .toString().length > 1
                          ? new Date(news.attributes.createdAt).getMinutes()
                          : `0${new Date(
                              news.attributes.createdAt
                            ).getMinutes()}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="w-full mb-5 mt-2 rounded"
                src={news.attributes.thumnail}
                alt=""
              />
            </div>
          </div>
          {ParserHTML(news.attributes.content)}
        </>
      )}
    </div>
  );
};

export default SportNewsById;
