import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import nookies, { parseCookies } from 'nookies';

const uri = process.env.REACT_APP_BACKEND_SERVER + '/graphql';

let httpLink = createHttpLink({
  uri: uri,
});

const GraphQL_API = ({ ctx = null }) => {
  const authLink = setContext((_, { headers }) => {
    var token = null;

    if (typeof window !== 'undefined') {
      const cookies = parseCookies();
      token = cookies.auth
        ? JSON.parse(cookies.auth).jwt
        : process.env.REACT_APP_API_TOKEN;
    } else {
      const cookies = nookies.get(ctx);
      token = cookies.auth
        ? JSON.parse(cookies.auth).jwt
        : process.env.REACT_APP_API_TOKEN;
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  httpLink = authLink.concat(httpLink);

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });
  return client;
};

export default GraphQL_API;
