import { gql } from '@apollo/client';

export const GET_LEAGUES = gql`
  query leagues($pagination: PaginationArg, $filters: LeagueFiltersInput) {
    leagues(pagination: $pagination, filters: $filters) {
      data {
        id
        attributes {
          league_name
          league_logo
        }
      }
    }
  }
`;

export const GET_LEAGUE_BY_ID = gql`
  query leagues($id: ID) {
    league(id: $id) {
      data {
        id
        attributes {
          league_name
          league_logo
          country {
            data {
              id
              attributes {
                country_name
                country_logo
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEAGUE_STANDING_BY_LEAGUE_ID = gql`
  query leagueStandings(
    $filters: LeagueStandingFiltersInput
    $pagination: PaginationArg
  ) {
    leagueStandings(filters: $filters) {
      data {
        id

        attributes {
          league {
            data {
              id
              attributes {
                league_name
                league_logo
              }
            }
          }
          table_type
          season
          status {
            color
            type
          }
          TeamInfo(pagination: $pagination) {
            id
            team {
              data {
                id
                attributes {
                  team_name
                  team_badge
                }
              }
            }
            MP
            W
            D
            L
            GF
            GA
            Pts
            last5
          }
          GroupState {
            id
            Group
            teams {
              id
              MP
              W
              D
              L
              GA
              Pts
              color
              team {
                data {
                  id
                  attributes {
                    team_name
                    team_badge
                  }
                }
              }
            }
          }
          RoundOfTeam
          Round {
            id
            team {
              data {
                attributes {
                  team_name
                  team_badge
                }
              }
            }
            Place {
              id
              placeNum
              result
              NextRound
            }
          }
        }
      }
    }
  }
`;

export const GET_LEAGUES_BY_MATCH = gql`
  query leagues_by_match(
    $league_filters: LeagueFiltersInput
    $filters: MatchFiltersInput
    $sort: [String]
    $pagination: PaginationArg
    $paginationMatch: PaginationArg
  ) {
    leagues(filters: $league_filters, pagination: $pagination) {
      data {
        id
        attributes {
          league_name
          league_logo
          createdAt
          country {
            data {
              id
              attributes {
                country_name
                country_logo
              }
            }
          }
          matches(
            filters: $filters
            sort: $sort
            pagination: $paginationMatch
          ) {
            data {
              id
              attributes {
                analysis
                match_time
                result
                home_team {
                  data {
                    id
                    attributes {
                      team_name
                      team_badge
                    }
                  }
                }
                away_team {
                  data {
                    id
                    attributes {
                      team_name
                      team_badge
                    }
                  }
                }
                match_date
                link {
                  id
                  link_name
                  link_type
                  link_url
                }
              }
            }
          }
        }
      }
    }
  }
`;
