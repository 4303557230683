import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UseStateContext } from '../context/StateContext';
import { ImHome, ImNewspaper } from 'react-icons/im';
import { IoFootballOutline } from 'react-icons/io5';
import { RiBoxingFill } from 'react-icons/ri';
import { MdLocalMovies } from 'react-icons/md';

const Menu = () => {
  const { user, openMenu, setOpenMenu, signOut } = UseStateContext();
  const router = useHistory();
  useEffect(() => {
    const mainMenu = document.getElementById('main-menu');
    if (openMenu) {
      document.getElementById('main-menu').style.left = '0%';
      setTimeout(() => {
        mainMenu.style.background = 'rgba(15, 23, 42, 0.5)';
        mainMenu.style.backdropFilter = 'blur(5px)';
      }, 200);
    } else {
      mainMenu.style.background = 'transparent';
      mainMenu.style.backdropFilter = 'none';
      setTimeout(() => {
        mainMenu.style.left = '-100%';
      }, 200);
    }
  }, [openMenu]);

  const closeMenu = () => {
    setOpenMenu(false);
  };

  return (
    <div
      id="main-menu"
      className={`h-screen w-screen fixed z-10 pt-[50px] text-white lg:hidden flex`}
      style={{
        transition: '300ms',
        left: '-100%',
        zIndex: 9,
      }}
    >
      <div className="konFont bg-[#1E293B] h-full min-w-[300px] max-w-[300px] p-2 pt-[30px] border-r border-gray-700 text-lg text-gray-300">
        <div>
          <button
            onClick={() => {
              router.push('/');
              closeMenu();
            }}
            type="button"
            className="mb-1 inline-flex items-center w-full py-1 px-2  font-[600] "
          >
            <ImHome className="mb-1 mx-5" /> ទំព័រដើម
          </button>
          <button
            onClick={() => {
              router.push('/football');
              closeMenu();
            }}
            type="button"
            className="mb-1 inline-flex items-center w-full py-1 px-2  font-[600] "
          >
            <IoFootballOutline className="mb-1 mx-5" />
            កីឡាបាល់ទាត់
          </button>
          {/* <button
            onClick={() => {
              router.push('/boxing');
              closeMenu();
            }}
            type="button"
            className="mb-1 inline-flex items-center w-full py-1 px-2  font-[600] "
          >
            <RiBoxingFill className="mb-1 mx-5" />
            ប្រដាល់
          </button> */}
          <button
            onClick={() => {
              router.push('/news');
              closeMenu();
            }}
            type="button"
            className="mb-1 inline-flex items-center w-full py-1 px-2  font-[600] "
          >
            <ImNewspaper className="mb-1 mx-5" />
            ព័ត៌មាន
          </button>
          {/* <button
            onClick={() => {
              window.open('https://www.drama855.com');
              closeMenu();
            }}
            type="button"
            className="mb-1 inline-flex items-center w-full py-1 px-2  font-[600] "
          >
            <MdLocalMovies className="mb-1 mx-5" />
            ភាពយន្ដ
          </button> */}
        </div>
        <div>
          <hr className="border-gray-700" />
          {user ? (
            <>
              <button
                onClick={() => {
                  signOut();
                  closeMenu();
                }}
                type="button"
                className="my-1 rounded bg-rose-800 hover:bg-rose-600 inline-flex items-center w-full py-1 px-2  text-white font-[600] "
              >
                ចេញពីគណនី
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  router.push('/auth/login');
                  closeMenu();
                }}
                type="button"
                className="mb-1 inline-flex items-center w-full py-1 px-2 font-[600] "
              >
                ចូលគណនី
              </button>
              <button
                onClick={() => {
                  router.push('/auth/register');
                  closeMenu();
                }}
                type="button"
                className="rounded mb-1 text-black bg-[#00AAFF] inline-flex items-center w-full py-1 px-2 font-[600] "
              >
                ចុះឈ្មោះ
              </button>
            </>
          )}
        </div>
      </div>
      <div className="w-full h-full" onClick={closeMenu}></div>
    </div>
  );
};

export default Menu;
