import { gql } from '@apollo/client';

export const GET_TEAMS = gql`
  query teams($pagination: PaginationArg, $filters: TeamFiltersInput) {
    teams(pagination: $pagination, filters: $filters) {
      data {
        id
        attributes {
          team_name
          team_badge
          createdAt
        }
      }
    }
  }
`;
