const isTrue = (cookies, type) => {
  if (!cookies.auth) {
    return false;
  }

  if (
    JSON.parse(cookies?.auth).user.attributes.role.data.attributes.name === type
  ) {
    return true;
  }
  return false;
};

export { isTrue };
