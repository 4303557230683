import React, { useEffect, useState } from 'react';

const GroupTeam = ({ team, t = 1, l = 1, place }) => {
  const [item, setItem] = useState(null);
  const [club, setClub] = useState(null);
  useEffect(() => {
    team.forEach((t) => {
      t.Place.forEach((p) => {
        if (p.placeNum === place) {
          setItem(p);
          setClub(t);
        }
      });
    });
  }, []);
  return (
    <div className={`absolute`} style={{ top: t + 'px', left: l + 'px' }}>
      {club ? (
        <>
          <div
            className={`w-[150px] p-[2px] px-1 rounded flex items-center justify-between border border-gray-700 h-[25px] bg-[#233044] ${
              item?.NextRound === 'next'
                ? 'font-[600] text-[#00AAFF] border-[#00AAFF]'
                : item?.NextRound === 'over'
                ? 'opacity-50 text-gray-400'
                : 'text-gray-300'
            }`}
          >
            <>
              <div className="flex items-center">
                <img
                  className="w-4 h-4 mr-1"
                  src={club.team.data.attributes.team_badge}
                  alt=""
                />
                <p className="truncate w-[120px] text-[12px]">
                  {club.team.data.attributes.team_name}
                </p>
              </div>
              <p
                className={`mr-1 text-xs ${
                  item?.NextRound === 'next' ? 'text-rose-600' : ''
                }`}
              >
                {item.result}
              </p>
            </>
          </div>
        </>
      ) : (
        <div
          className={`${
            item?.NextRound && 'font-[600] text-[#00AAFF] bg-[#354054]'
          } text-gray-300 w-[150px] p-[2px] px-1 rounded flex items-center justify-between border border-gray-700 h-[25px]`}
        >
          <div className="flex items-center">
            <img
              className="w-4 h-4 mr-1 opacity-25"
              src="https://www.sofascore.com/static/images/placeholders/team.svg"
              alt=""
            />
            <p className="text-xs opacity-75">TBD</p>
          </div>
          <p className="mr-1"></p>
        </div>
      )}
    </div>
  );
};

export default GroupTeam;
