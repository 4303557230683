import React, { useState } from 'react';
import { useEffect } from 'react';
import { HiXMark } from 'react-icons/hi2';
import GraphQL_API, { TeamGQL } from '../../graphql';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';

const PopupTeam = ({ func }) => {
  const client = GraphQL_API({ ctx: null });
  const [open, setOpen] = useState(false);
  const [teams, setTeams] = useState([]);

  const close = () => {
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  const accept = async (t) => {
    await func(t);
    close();
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const fetchData = async (name) => {
    const { data: teams } = await client.query({
      query: TeamGQL.GET_TEAMS,
      variables: {
        filters: {
          team_name: {
            contains: name,
          },
        },
        pagination: {
          pageSize: 20,
        },
      },
    });
    if (teams) {
      setTeams(teams.teams.data);
    }
  };

  return (
    <div className="relative max-w-[70px] flex">
      <button onClick={() => setOpen(true)}>
        <AiOutlineAppstoreAdd
          className="text-[#00AAFF] opacity-70 hover:opacity-100"
          size={20}
        />
      </button>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 z-20"
        style={{
          display: open ? 'block' : 'none',
          background: 'rgba(15, 23, 42, 0.5)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <div className="flex justify-center items-center h-screen">
          <div className="bg-[#0D1426] w-[350px] p-2 drop-shadow border border-gray-800">
            <div className="flex justify-between">
              <p className="text-[#00AAFF] font-bold">ក្រុម</p>
              <HiXMark
                className="cursor-pointer opacity-60 hover:opacity-100"
                size={20}
                onClick={close}
              />
            </div>
            <div className="mb-2 mt-1">
              <input
                className="p-1 font-[400] px-2 rounded w-full bg-[#0F172A] focus:outline-none border border-gray-800"
                type="text"
                placeholder="ឈ្មោះក្រុម..."
                onChange={(e) => fetchData(e.target.value)}
              />
            </div>
            <div className="h-full overflow-y-auto h-[500px] w22Scroll">
              {teams.map((t) => (
                <div
                  onClick={() => accept(t)}
                  key={t.id}
                  className="cursor-pointer flex items-center p-2 hover:bg-[#0F172A] border border-transparent hover:border-gray-800"
                >
                  <img
                    className="w-6 h-6 mr-2"
                    src={t.attributes.team_badge}
                    alt=""
                  />
                  <p className="mx-line-1 font-[600]">
                    {t.attributes.team_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupTeam;
