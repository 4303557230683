import { gql } from '@apollo/client';

export const GET_IMG_SLIDER = gql`
  query imgSlide {
    imgSlide {
      data {
        id
        attributes {
          imgUrl {
            id
            url
          }
        }
      }
    }
  }
`;
