import { gql } from '@apollo/client';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

export default function Editor1({ handleChange, ...props }) {
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then(async (file) => {
            formData.append('image', file);
            const config = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            };
            try {
              const { data } = await axios.post(
                `/api/uploads`,
                formData,
                config
              );
              if (data) {
                resolve({
                  default: data,
                });
              }
            } catch (error) {
              reject(error);
            }
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className="text-gray-300">
      {editorLoaded ? (
        <CKEditor
          config={{
            extraPlugins: [uploadPlugin],
            mediaEmbed: {
              previewsInData: true,
            },
          }}
          editor={ClassicEditor}
          onReady={(editor) => {}}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
          onChange={(event, editor) => {
            handleChange(editor.getData());
          }}
          {...props}
        />
      ) : (
        <p className="text-center">loading</p>
      )}
    </div>
  );
}
