import React from 'react';
import { useHistory } from 'react-router-dom';
import { UseTimeContext } from '../context/TimeContext';
import Moment from 'react-moment';
import { TbListDetails } from 'react-icons/tb';
import PopupDetails from './PopupDetails';

const MatchCom = ({ m }) => {
  const { currentTime } = UseTimeContext();
  const router = useHistory();
  return (
    <>
      <div className="relative p-2 flex justify-center flex-col sm:flex-row drop-shadow-xl bg-[#1E293B] border-t border-gray-600">
        <div className="min-w-[100px] flex justify-center sm:justify-start flex-row text-xs">
          <div className="flex flex-row sm:flex-col md:order-0">
            <p className="text-gray-300">
              <Moment format="DD MMM, YY">
                {new Date(m.attributes.match_date)}
              </Moment>{' '}
            </p>

            <p className="text-white mx-2 sm:hidden">|</p>

            {currentTime > new Date(m.attributes.match_date).getTime() &&
            currentTime <
              new Date(m.attributes.match_date).getTime() +
                1000 * 60 * 60 * 2.2 &&
            m.attributes.link.length !== 0 ? (
              <div className="relative text-xs border border-rose-600 px-1 rounded-sm bg-rose-600">
                <span className="flex h-2 w-2 absolute top-[4px]">
                  <span className="animate-ping absolute h-2 w-2 rounded-full bg-white "></span>
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-white opacity-75"></span>
                </span>
                <p className="text-white font-bold pl-3 khFont">ផ្សាយផ្ទាល់</p>
              </div>
            ) : (
              <>
                {m.attributes.match_time ? (
                  <p className="text-rose-600 font-bold text-md">
                    {m.attributes.match_time}
                  </p>
                ) : (
                  <p className="font-bold text-[#00AAFF] text-xs">
                    <span className="font-bold">
                      {new Date(m.attributes.match_date).getHours() === 0
                        ? 12
                        : new Date(m.attributes.match_date).getHours() > 12
                        ? new Date(m.attributes.match_date).getHours() - 12
                        : new Date(m.attributes.match_date).getHours()}
                      :
                      {new Date(m.attributes.match_date).getMinutes().toString()
                        .length > 1
                        ? new Date(m.attributes.match_date).getMinutes()
                        : `0${new Date(m.attributes.match_date).getMinutes()}`}
                    </span>{' '}
                    {new Date(m.attributes.match_date).getHours() < 12
                      ? 'AM'
                      : 'PM'}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center items-center order-1 md:order-2 my-2 sm:my-0">
          <div className="w-6/12 flex flex-row items-center justify-end">
            <div className="text-xs md:text-sm text-gray-300 text-center truncate font-[600]">
              {m.attributes.home_team.data.attributes.team_name}
            </div>
            <img
              className="w-5 ml-2"
              src={m.attributes.home_team.data.attributes.team_badge}
              alt=""
            />
          </div>
          <div className="min-w-[50px] flex justify-center items-center text-[#00AAFF] font-bold">
            {(m.attributes.result && (
              <div className="flex">
                <p className="text-rose-600">{m.attributes.result.home_gold}</p>
                <p className="mx-1 text-gray-500"> - </p>
                <p className="text-rose-600">{m.attributes.result.away_gold}</p>
              </div>
            )) ||
              '-'}
          </div>

          <div className="w-6/12 flex items-center justify-start">
            <img
              className="w-5 mr-2"
              src={m.attributes.away_team.data.attributes.team_badge}
              alt=""
            />
            <div className="font-[600] text-xs md:text-sm text-gray-300 text-center truncate">
              {m.attributes.away_team.data.attributes.team_name}
            </div>
          </div>
        </div>
        <div className="min-w-[100px] flex justify-center sm:justify-end items-center order-3">
          {m.attributes.link.map((link) => (
            <React.Fragment key={link.id}>
              {/* <MdLiveTv
                title="ផ្សាយផ្ទាល់"
                key={link.id}
                size={18}
                className="text-rose-600 hover:text-rose-400 cursor-pointer mx-1"
                onClick={() => {
                  //   window.location.href = `https://live.sportliveft.com?id=${m.id}`;
                  window.location.href = `http://192.168.1.100:5500/index.html?id=${m.id}&n=${link.link_name}&k=${link.link_url}&u=ចំរើន}`;
                }}
              /> */}
              <button
                title="ផ្សាយផ្ទាល់"
                className="cursor-pointer bg-rose-600 hover:bg-rose-500 mx-1 text-xs px-1 text-white rounded-sm"
                onClick={() => {
                  if (link.link_type === 'other_site') {
                    window.open(link.link_url);
                    return;
                  }
                  router.push(`/matching/${m.id}?linkId=${link.id}`);
                }}
              >
                {link.link_type === 'Live' ? 'មើល' : link.link_name}
              </button>
            </React.Fragment>
          ))}
          <PopupDetails details={m.attributes.analysis || ''} />
          <TbListDetails
            title="ព័ត៌មានលម្អិត"
            size={16}
            className="text-green-600 hover:text-green-400 cursor-pointer mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default MatchCom;
