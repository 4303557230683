import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GraphQL_API, { LeagueGQL } from '../graphql';

const Football = () => {
  const router = useHistory();
  const client = GraphQL_API({ ctx: null });
  const [leagues, setLeagues] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const { data } = await client.query({
      query: LeagueGQL.GET_LEAGUES,
      variables: {
        pagination: {
          pageSize: 100,
        },
      },
    });

    if (data) {
      setLeagues(data.leagues.data);
    }
  };
  return (
    <div className="mx-auto max-w-[1300px] text-gray-300 mt-2 md:mt-[20px]">
      <div className="flex flex-col md:flex-row items-center mb-3">
        <img
          className="w-[300px] cursor-pointer hover:opacity-90"
          src="/banner/wing288logo.png"
          onClick={() => window.open('https://m.wing288.me/?fid=WINGTYAA')}
          alt=""
        />
        <div className="md:text-lg font-[600] px-2 text-center">
          Wing288 ជាវេបសាយកម្សាន្ដអនឡាញដែលមានកីឡា 💫ចុះឈ្មោះ-ដំបូងទទួលទឹកប្រាក់
          111$ ដុល្លារ និង ☆ ទឹកប្រាក់បង្វិលជូន រហូតដល់ 2021$ ☆ បន្ថែម 28%
          លើការលេងបាការ៉ាត់ និង កេណូ។ 📲 ទនាក់ទំនងចុះឈ្មោះតារយ: Inbox ☎️
          <span className="text-rose-600">087372288</span>,{' '}
          <span className="text-rose-600">012372288</span>,{' '}
          <span className="text-rose-600">090372288</span>{' '}
          <p
            className="underline text-[#00AAFF] cursor-pointer hover:opacity-90"
            onClick={() => window.open('https://m.wing288.me/?fid=WINGTYAA')}
          >
            👉លីងចុះឈ្មោះខ្លួនឯងជាមួយ Wing288
          </p>
        </div>
      </div>
      <p className="text-center mb-2 font-bold text-lg text-[#00AAFF]">លីគ</p>
      <div className="flex flex-wrap justify-evenly ">
        {leagues.map((l) => (
          <div
            key={l.id}
            onClick={() => router.push(`/leagues/${l.id}`)}
            className="flex items-center bg-[#1E293B] mb-2 mx-1 p-1 w-[170px] border border-gray-700 rounded cursor-pointer hover:text-black hover:bg-[#00AAFF]"
          >
            <img className="w-6 mr-1" src={l.attributes.league_logo} alt="" />
            <p className="text-sm mx-line-1 font-[600]">
              {l.attributes.league_name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Football;
