import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import StateContext from './context/StateContext';
import TimeContext from './context/TimeContext';
import Layout from './layout/Layout';
import Dashboard from './screens/Dashboard';
import './css.css';
import Menu from './components/manu';
import Match from './screens/Match';
import Login from './screens/Login';
import Iframe from './screens/Iframe';
import Register from './screens/Register';
import DynamicRoute from './utils/DynamicRoute';
import NewsScreen from './admin/NewsScreen';
import FootballScreen from './admin/FootballScreen';
import CreateNews from './admin/CreateNews';
import SportNews from './screens/SportNews';
import SportNewsById from './screens/SportNewsById';
import League from './screens/League';
import Football from './screens/Football';
import Boxing from './screens/Boxing';
import BoxingById from './screens/BoxingById';
import CreateMatch from './admin/CreateMatch';
import UpdateMatch from './admin/UpdateMatch';
import Boxings from './admin/Boxings';
import CreateBoxing from './admin/CreateBoxing';

function App() {
  return (
    <StateContext>
      <TimeContext>
        <Router>
          <Menu />
          <Layout>
            <Switch>
              <Route path="/" component={Dashboard} exact />
              <Route path="/football" component={Football} />
              <Route path="/boxing/:bid" component={BoxingById} />
              <Route path="/boxing" component={Boxing} />
              <Route path="/news/:nid" component={SportNewsById} />
              <Route path="/news" component={SportNews} />
              <Route path="/leagues/:lid" component={League} />
              <Route path="/matching/:mid/iframe" component={Iframe} />
              <Route path="/matching/:mid" component={Match} />
              <DynamicRoute path="/auth/login" component={Login} noAuth />
              <DynamicRoute path="/auth/register" component={Register} noAuth />
              <DynamicRoute
                path="/admin/news/create"
                component={CreateNews}
                admin
              />
              <DynamicRoute
                path="/admin/news/update/:nid"
                component={CreateNews}
                admin
              />
              <DynamicRoute path="/admin/news" component={NewsScreen} admin />
              <DynamicRoute
                path="/admin/football/update/:mid"
                component={UpdateMatch}
                admin
              />
              <DynamicRoute
                path="/admin/football/create"
                component={CreateMatch}
                admin
              />
              <DynamicRoute
                path="/admin/football"
                component={FootballScreen}
                admin
              />
              <DynamicRoute
                path="/admin/boxing/update/:bid"
                component={CreateBoxing}
                admin
              />
              <DynamicRoute
                path="/admin/boxing/create"
                component={CreateBoxing}
                admin
              />
              <DynamicRoute path="/admin/boxing" component={Boxings} admin />
            </Switch>
          </Layout>
        </Router>
      </TimeContext>
    </StateContext>
  );
}

export default App;
