import React, { useState } from 'react';
import AdminLayout from './com/AdminLayout';
import PopupTeam from './com/PopupTeam';
import PopupLeague from './com/PopupLeague';
import PopupCountry from './com/PopupCountry';
import GraphQL_API, { MatchGQL } from '../graphql';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const UpdateMatch = () => {
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const { mid } = useParams();
  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  const [league, setLeague] = useState(null);
  const [country, setCountry] = useState(null);
  const [matchDate, setMatchDate] = useState(null);
  const [linkName, setLinkName] = useState(null);
  const [linkUrl, setLinkUrl] = useState(null);
  const [linkType, setLinkType] = useState(null);
  const [linkID, setLinkID] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const { data } = await client.query({
      query: MatchGQL.GET_MATCH_BY_ID,
      variables: {
        id: mid,
      },
    });
    if (data) {
      const m = data.match.data.attributes;
      setCountry(m.country.data);
      setLeague(m.league.data);
      setHomeTeam(m.home_team.data);
      setAwayTeam(m.away_team.data);
      setAwayTeam(m.away_team.data);
      setMatchDate(m.match_date);
      setLinkName(m.link[0].link_name);
      setLinkUrl(m.link[0].link_url);
      setLinkType(m.link[0].link_type);
      setLinkID(m.link[0].id);
      console.log(m);
    }
  };

  const updateMatch = async () => {
    setMessage(null);
    if (!homeTeam) {
      setMessage('សូមបញ្ចូលក្រុមម្ចាស់ផ្ទះ');
      return;
    }
    if (!awayTeam) {
      setMessage('សូមបញ្ចូលក្រុមភ្ញៀវ');
      return;
    }
    if (!country) {
      setMessage('សូមបញ្ចូលប្រទេស');
      return;
    }
    if (!league) {
      setMessage('សូមបញ្ចូលលីគ');
      return;
    }

    if (!matchDate) {
      setMessage('សូមបញ្ចូលថ្ងៃខែប្រកួត');
      return;
    }
    if (!linkName) {
      setMessage('សូមបញ្ចូលឈ្មោះលីង');
      return;
    }
    if (!linkUrl) {
      setMessage('សូមបញ្ចូលលីងឬ Stream key');
      return;
    }
    if (!linkType) {
      setMessage('សូមបញ្ចូលជ្រើសរើសប្រភេទលីង');
      return;
    }

    try {
      const { data } = await client.mutate({
        mutation: MatchGQL.UPDATE_MATCH,
        variables: {
          id: mid,
          data: {
            country: country.id,
            home_team: homeTeam.id,
            away_team: awayTeam.id,
            league: league.id,
            link: [
              {
                id: linkID,
                link_url: linkUrl,
                link_name: linkName,
                link_type: linkType,
              },
            ],
            match_date: matchDate,
          },
        },
      });
      if (data) {
        router.push('/admin/football');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatTime = (d) => {
    const time = new Date(d);
    const date = time.getDate();
    const month = time.getMonth() + 1;
    const year = time.getFullYear();
    const h = time.getHours();
    const m = time.getMinutes();

    return `${year}-${month >= 10 ? month : `0${month}`}-${
      date >= 10 ? date : `0${date}`
    }T${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}`;
  };

  return (
    <AdminLayout>
      <div className="sticky top-[57px] bg-[#0F172A] p-2 flex justify-between z-10 shadow">
        <p className="font-bold">⚽ បង្កើតការប្រកួត</p>
        {message && (
          <p className="text-sm text-rose-600 font-[500]">!{message}</p>
        )}
        <div className="flex">
          <button
            onClick={updateMatch}
            className="bg-[#00AAFF] hover:bg-sky-400 text-black font-bold p-1 px-4 mx-1"
          >
            រក្សាទុក
          </button>
          <button
            onClick={() => router.push('/admin/football')}
            className="opacity-60 hover:opacity-100 bg-white text-black font-bold p-1 px-4 mx-1"
          >
            បោះបង់
          </button>
        </div>
      </div>
      <div className="p-2">
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
          <div className="bg-[#0D1426] border border-gray-800 h-[180px]">
            <div className="font-[600] flex justify-between p-2">
              <p>ក្រុមម្ចាស់ផ្ទះ</p>
              <PopupTeam func={(team) => setHomeTeam(team)} />
            </div>
            {homeTeam && (
              <div className="flex flex-col items-center p-2 w-full">
                <img
                  className="w-20 h-20 mb-3"
                  src={homeTeam.attributes.team_badge}
                  alt=""
                />
                <p className="mx-line-1 font-bold text-[#00AAFF]">
                  {homeTeam.attributes.team_name}
                </p>
              </div>
            )}
          </div>
          <div className="bg-[#0D1426] border border-gray-800 h-[180px]">
            <div className="font-[600] flex justify-between p-2">
              <p>ក្រុមភ្ញៀវ</p>
              <PopupTeam func={(team) => setAwayTeam(team)} />
            </div>
            {awayTeam && (
              <div className="flex flex-col items-center p-2 w-full">
                <img
                  className="w-20 h-20 mb-3"
                  src={awayTeam.attributes.team_badge}
                  alt=""
                />
                <p className="mx-line-1 font-bold text-[#00AAFF]">
                  {awayTeam.attributes.team_name}
                </p>
              </div>
            )}
          </div>
          <div className="bg-[#0D1426] border border-gray-800 h-[180px]">
            <div className="font-[600] flex justify-between p-2">
              <p>ប្រទេស</p>
              <PopupCountry func={(c) => setCountry(c)} />
            </div>
            {country && (
              <div className="flex flex-col items-center p-2 w-full">
                <img
                  className="w-30 h-20 mb-3 object-cover"
                  src={country.attributes.country_logo}
                  alt=""
                />
                <p className="mx-line-1 font-bold text-[#00AAFF]">
                  {country.attributes.country_name}
                </p>
              </div>
            )}
          </div>
          <div className="bg-[#0D1426] border border-gray-800 h-[180px]">
            <div className="font-[600] flex justify-between p-2">
              <p>លីគ</p>
              <PopupLeague func={(l) => setLeague(l)} />
            </div>
            {league && (
              <div className="flex flex-col items-center p-2 w-full">
                <img
                  className="w-20 h-20 mb-3"
                  src={league.attributes.league_logo}
                  alt=""
                />
                <p className="mx-line-1 font-bold text-[#00AAFF]">
                  {league.attributes.league_name}
                </p>
              </div>
            )}
          </div>
          <div className="bg-[#0D1426] border border-gray-800 h-[180px]">
            <div className="font-[600] flex justify-between p-2">
              <p>ថ្ងៃខែប្រកួត</p>
              {matchDate && (
                <input
                  className="focus:outline-none bg-transparent text-gray-300"
                  type="datetime-local"
                  value={formatTime(matchDate)}
                  onChange={(e) =>
                    setMatchDate(new Date(e.target.value).toISOString())
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 bg-[#0D1426] border border-gray-800 p-2">
          <div className="flex items-center">
            <p className="font-bold mr-2">លីង</p>
            <p className="font-bold"></p>
          </div>
          <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
            <input
              className="focus:outline-none p-2 bg-[#0F172A] border border-gray-800"
              type="text"
              placeholder="ឈ្មោះលីង..."
              value={linkName}
              onChange={(e) => setLinkName(e.target.value)}
            />
            <input
              className="focus:outline-none p-2 bg-[#0F172A] border border-gray-800"
              type="text"
              placeholder="លីង-Stream Key..."
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
            />
            <select
              value={linkType}
              onChange={(e) => setLinkType(e.target.value)}
              className="focus:outline-none p-2 bg-[#0F172A] border border-gray-800"
            >
              <option value="">ជ្រើសរើសប្រភេទលីង</option>
              <option value="embed">Embed</option>
              <option value="Live">LIVE</option>
              <option value="m3u8">m3u8</option>
            </select>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default UpdateMatch;
