import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import GraphQL_API, { LeagueGQL, MatchGQL } from '../graphql';
import Moment from 'react-moment';
import { GoLinkExternal } from 'react-icons/go';
import LeagueStand from '../components/LeagueStand';

const League = () => {
  const { lid } = useParams();
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const [league, setLeague] = useState();
  const [season, setSeason] = useState();
  const [matches, setMatches] = useState([]);
  const [mMeta, setMMeta] = useState();
  const currentTime = new Date().getTime();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // league
    const { data: league } = await client.query({
      query: LeagueGQL.GET_LEAGUE_BY_ID,
      variables: {
        id: lid,
      },
    });

    if (league) {
      setLeague(league.league.data);
    }
    // season
    const { data: seasons } = await client.query({
      query: LeagueGQL.GET_LEAGUE_STANDING_BY_LEAGUE_ID,
      variables: {
        filters: {
          and: [
            {
              league: {
                id: {
                  eq: lid,
                },
              },
            },
            {
              season: {
                eq: '23-24',
              },
            },
          ],
        },
        pagination: {
          limit: 20,
        },
      },
    });

    if (seasons && seasons.leagueStandings.data[0]) {
      setSeason(seasons.leagueStandings.data[0]);
      console.log(seasons && seasons.leagueStandings.data[0]);
    }

    // matches
    MatchesWithPagination(1);
  };

  const MatchesWithPagination = async (p) => {
    var curr = new Date();
    var live = curr.getTime() - 1000 * 60 * 60 * 2;
    // var gt = new Date(live);
    var end = new Date(curr.getTime() + 1000 * 60 * 60 * 24 * 7);

    const { data: matches } = await client.query({
      query: MatchGQL.GET_MATCHES,
      variables: {
        filters: {
          and: [
            {
              league: {
                id: {
                  eq: lid,
                },
              },
            },
            {
              match_date: {
                lt: end,
              },
            },
          ],
        },
        sort: ['match_date:desc'],
        pagination: {
          pageSize: 10,
          page: p,
        },
      },
    });
    if (matches) {
      setMatches(matches.matches.data);
      setMMeta(matches.matches.meta.pagination);
    }
  };

  if (!league) return <p className="text-center text-white">Loading...</p>;
  return (
    <div className="max-w-[1300px] mx-auto text-white">
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-8/12 p-1">
          <div className="flex bg-[#0c8ac9] items-center p-1 rounded-md">
            <img
              className="w-20 md:w-36"
              src={league.attributes.league_logo}
              alt=""
            />
            <div className="ml-5">
              <p className="text-lg md:text-3xl font-bold">
                {league.attributes.league_name}
              </p>
              <div className="mt-3">
                <div className="flex items-center">
                  <img
                    className="w-7 h-7"
                    src={league.attributes.country.data.attributes.country_logo}
                    alt=""
                  />
                  <p className="mx-2 text-sm ">
                    {league.attributes.country.data.attributes.country_name}
                  </p>
                </div>
                <div className="flex">
                  <p className="khFont mt-2">
                    រដូវកាល
                    <select
                      className="bg-[#0F172A] text-[#00AAFF] rounded ml-2 font-[600]"
                      name="season"
                      id="season"
                    >
                      <option value="22-23">23-24</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <LeagueStand season={season} />
        </div>
        <div className="w-full lg:w-4/12 p-1">
          <div className="rounded-md overflow-hidden border border-gray-600">
            <p className="bg-[#1E293B] p-2 text-center font-bold text-[#00AAFF]">
              ការប្រកួតក្នុងលីគ
            </p>
            {matches.map((m) => (
              <div key={m.id} className="border-t border-gray-800 pt-1">
                <div className="text-sm flex justify-center ">
                  <p className="mr-2 text-gray-300">
                    <Moment format="MMM DD, YY">
                      {new Date(m.attributes.match_date)}
                    </Moment>{' '}
                    |
                  </p>

                  <div className="flex md:order-0 items-center font-[500]">
                    {currentTime >
                      new Date(m.attributes.match_date).getTime() &&
                    currentTime <
                      new Date(m.attributes.match_date).getTime() +
                        1000 * 60 * 60 * 2.2 &&
                    m.attributes.link.length !== 0 ? (
                      <div className="relative mx-2">
                        <span className="flex h-2 w-2 absolute top-[6px] left-1">
                          <span className="animate-ping absolute h-2 w-2 rounded-full bg-rose-600 "></span>
                          <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-600 opacity-75"></span>
                        </span>
                        <p className="text-rose-600 font-[600] pl-5 pr-3 pb-[2px] khFont">
                          ផ្សាយផ្ទាល់
                        </p>
                      </div>
                    ) : (
                      <p className="text-[#00AAFF] font-bold">
                        {new Date(m.attributes.match_date).getHours()}:
                        {new Date(m.attributes.match_date)
                          .getMinutes()
                          .toString().length > 1
                          ? new Date(m.attributes.match_date).getMinutes()
                          : `0${new Date(
                              m.attributes.match_date
                            ).getMinutes()}`}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-1 text-center">
                  <div>
                    <img
                      className="w-8 mx-auto mb-1"
                      src={m.attributes.home_team.data.attributes.team_badge}
                      alt=""
                    />
                    <p className="text-[11px] font-[500] text-gray-300">
                      {m.attributes.home_team.data.attributes.team_name}
                    </p>
                  </div>
                  <div className="mt-2 text-[#00AAFF] font-bold">
                    {(m.attributes.result && m.attributes.result) || '-'}
                  </div>
                  <div>
                    <img
                      className="w-8 mx-auto mb-1"
                      src={m.attributes.away_team.data.attributes.team_badge}
                      alt=""
                    />
                    <p className="text-[11px] font-[500] text-gray-300">
                      {m.attributes.away_team.data.attributes.team_name}
                    </p>
                  </div>
                </div>
                <div className="px-3 text-center mb-1">
                  <GoLinkExternal
                    onClick={() =>
                      router.push(
                        `/matching/${m.id}?linkId=${m.attributes.link[0].id}`
                      )
                    }
                    className="mx-auto hover:text-[#00AAFF] cursor-pointer"
                  />
                </div>
              </div>
            ))}
          </div>
          {mMeta && mMeta.pageCount !== 1 && (
            <div className="grid grid-cols-3 text-sm p-2 py-1 bg-[#1E293B] mt-2 rounded-md">
              <button
                onClick={() => {
                  if (mMeta.page !== 1) {
                    MatchesWithPagination(mMeta.page - 1);
                  }
                }}
                className={`${
                  mMeta.page !== 1
                    ? 'hover:underline cursor-pointer text-white'
                    : 'text-gray-500'
                }`}
              >
                Prev
              </button>
              <div className="text-center font-bold text-base text-[#00AAFF]">
                {mMeta.page}
              </div>
              <button
                onClick={() => {
                  if (mMeta.page !== mMeta.pageCount) {
                    MatchesWithPagination(mMeta.page + 1);
                  }
                }}
                className={`${
                  mMeta.page !== mMeta.pageCount
                    ? 'hover:underline cursor-pointer text-white'
                    : 'text-gray-500'
                }`}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default League;
