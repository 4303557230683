import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        username
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUsersPermissionsUser(
    $data: UsersPermissionsUserInput!
    $id: ID!
  ) {
    updateUsersPermissionsUser(data: $data, id: $id) {
      data {
        id
        attributes {
          username
          email
          color
          confirmed
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        username
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query usersPermissionsUser($id: ID) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          username
          email
          color
          confirmed
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;
