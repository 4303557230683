import React from 'react';
import AdminNav from './AdminNav';

const AdminLayout = ({ children }) => {
  return (
    <div className="mx-auto text-gray-300 flex">
      <AdminNav />
      <div className="w-full mb-5">{children}</div>
    </div>
  );
};

export default AdminLayout;
