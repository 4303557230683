import { gql } from '@apollo/client';

export const GET_COUNTRY = gql`
  query countries($pagination: PaginationArg, $filters: CountryFiltersInput) {
    countries(pagination: $pagination, filters: $filters) {
      data {
        id
        attributes {
          country_name
          country_logo
        }
      }
    }
  }
`;
