import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const AdminNav = () => {
  const [height, setHeight] = useState(window.innerHeight - 58);
  useEffect(() => {
    setHeight(window.innerHeight - 58);
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight - 58);
    });
  }, [window]);
  return (
    <div className="min-w-[250px] bg-[#0d1426]">
      <div className={`sticky top-[58px]`}>
        <div
          className="overflow-y-auto font-bold konFont adminNav border-r border-gray-800"
          style={{ height: height ? height + 'px' : '80vh' }}
        >
          <NavLink
            to={'/admin/news'}
            className="block p-2 pl-4 border-l-4 border-transparent"
            activeClassName="bg-rose-900 border-white"
          >
            ព័ត៌មានកីឡា
          </NavLink>
          <NavLink
            to={'/admin/football'}
            className="block p-2 pl-4 border-l-4 border-transparent"
            activeClassName="bg-rose-900 border-white"
          >
            កីឡាបាល់ទាត់
          </NavLink>
          <NavLink
            to={'/admin/boxing'}
            className="block p-2 pl-4 border-l-4 border-transparent"
            activeClassName="bg-rose-900 border-white"
          >
            កីឡាប្រដាល់
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AdminNav;
