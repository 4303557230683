import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GraphQL_API, { BoxingGQL } from '../graphql';
import AdminLayout from './com/AdminLayout';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';

const Boxings = () => {
  const router = useHistory();
  const client = GraphQL_API({ ctx: null });
  const [boxings, setBoxings] = useState([]);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = async (page) => {
    const { data: boxings } = await client.query({
      query: BoxingGQL.GET_BOXINGS,
      variables: {
        sort: ['createdAt:desc'],
        pagination: {
          page: page,
          pageSize: 10,
        },
      },
    });
    if (boxings) {
      setPagination(boxings.boxings.meta.pagination);
      setBoxings(boxings.boxings.data);
    }
  };

  const delBoxing = async (id) => {
    if (window.confirm(`Delete?`) === true) {
      const { data } = await client.mutate({
        mutation: BoxingGQL.DELETE_BOXING,
        variables: { id: id },
      });
      if (data) {
        fetchData(pagination.page);
      }
    }
  };

  return (
    <AdminLayout>
      <div className="sticky top-[57px] bg-[#0F172A] p-2 flex justify-between z-10 shadow">
        <p className="font-bold">👊 កីឡាប្រដាល់</p>
        <button
          onClick={() => router.push(`/admin/boxing/create`)}
          className="bg-rose-600 rounded hover:bg-sky-400 text-black font-bold px-3"
        >
          បង្កើតថ្មី
        </button>
      </div>
      <div className="h-[3000px] p-2">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
          {boxings.map((b) => (
            <div
              key={b.id}
              className="bg-[#1E293B] border border-gray-700 rounded shadow relative"
            >
              <img
                className="object-cover h-[200px] w-full rounded-t"
                src={b.attributes.thumbnail}
                alt=""
              />
              <div className="flex justify-end p-1 absolute top-1 right-0">
                <AiFillEdit
                  onClick={() => router.push(`/admin/boxing/update/${b.id}`)}
                  size={25}
                  className="shadow border border-gray-400 bg-white rounded p-1 mx-1 cursor-pointer text-black hover:opacity-70"
                />
                <AiFillDelete
                  onClick={() => delBoxing(b.id)}
                  size={25}
                  className="shadow border border-gray-400  bg-white rounded p-1 mx-1 cursor-pointer text-rose-600 hover:opacity-70"
                />
              </div>
              <div className="p-2">
                <p className="mx-line-1 text-sm font-[500]">
                  {b.attributes.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Boxings;
