import React, { useEffect, useState } from 'react';
import GraphQL_API, { BoxingGQL } from '../graphql';
import Moment from 'react-moment';
import { LinkItUrl } from 'react-linkify-it';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import SideBanner from '../components/SideBanner';

const Boxing = () => {
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const [boxings, setBoxings] = useState([]);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = async (page) => {
    const { data: boxings } = await client.query({
      query: BoxingGQL.GET_BOXINGS,
      variables: {
        sort: ['createdAt:desc'],
        pagination: {
          page: page,
          pageSize: 10,
        },
      },
    });
    if (boxings) {
      setPagination(boxings.boxings.meta.pagination);
      if (page === 1) {
        setBoxings(boxings.boxings.data);
        return;
      }
      setBoxings((b) => {
        return [...b, ...boxings.boxings.data];
      });
    }
  };

  return (
    <div className="flex justify-around text-gray-100">
      <div className="hidden lg:block w-full max-w-[280px] xl:max-w-[300px] p-1">
        <div className="sticky top-[92px]">
          <SideBanner />
        </div>
      </div>
      <div className="w-full max-w-[600px] p-1 ">
        {boxings.map((b) => (
          <div key={b.id} className="rounded-xl p-1 bg-[#1E293B] shadow mb-3">
            <div className="text-gray-300 p-2">
              <div className="mb-2 flex">
                <img
                  className="w-10 h-10 rounded-full mr-3"
                  src="/user2.png"
                  alt=""
                />
                <div>
                  <p>
                    <span className="font-bold text-[#00AAFF]">
                      {b.attributes.admin.data.attributes.username}
                    </span>{' '}
                    {b.attributes.isLive && (
                      <span className="text-sm">
                        ផ្សាយបន្ដផ្ទាល់{' '}
                        <span className="text-base font-bold text-rose-600">
                          កីឡាប្រដាល់
                        </span>
                      </span>
                    )}
                  </p>
                  <p className="text-xs">
                    <Moment fromNow>{b.attributes.createdAt}</Moment>
                  </p>
                </div>
              </div>
              <p className="font-[600] mx-line-2">
                <LinkItUrl className="text-[#00AAFF] hover:underline">
                  {b.attributes.title}
                </LinkItUrl>
              </p>
            </div>
            <div
              className="relative cursor-pointer"
              onClick={() => router.push(`/boxing/${b.id}`)}
            >
              {b.attributes.isLive && (
                <div className="absolute bg-rose-600 text-white rounded shadow text-sm font-[600] px-1 top-2 left-2">
                  LIVE
                </div>
              )}
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <BsFillPlayCircleFill
                  size={60}
                  className="text-white drop-shadow-lg cursor-pointer hover:text-gray-200"
                />
              </div>
              <img
                className="w-full min-h-[450px] object-cover rounded-b-lg"
                src={b.attributes.thumbnail}
                alt=""
              />
            </div>
          </div>
        ))}
        {pagination && pagination.page < pagination.pageCount && (
          <div className="w-full flex justify-center">
            <span
              onClick={() => fetchData(pagination.page + 1)}
              className="text-xs font-bold border border-gray-600 hover:bg-[#1E293B] rounded p-2 px-3 cursor-pointer opacity-80 hover:opacity-100"
            >
              LOAD MORE
            </span>
          </div>
        )}
      </div>
      <div className="hidden md:block w-full max-w-[280px] xl:max-w-[300px] p-1">
        <div className="sticky top-[92px]">
          <SideBanner />
        </div>
      </div>
    </div>
  );
};

export default Boxing;
