import React, { useEffect } from 'react';
import { IoSendSharp } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { UseStateContext } from '../context/StateContext';
import GraphQL_API, { CommentGQL } from '../graphql';
import ScrollToBottom from 'react-scroll-to-bottom';

const Comment = ({
  _createCommet,
  mid,
  id,
  comments,
  HC,
  sport = 'football',
  linkId,
  setComments,
}) => {
  const { user } = UseStateContext();
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();

  useEffect(() => {
    fetchComment();
  }, []);

  const fetchComment = async () => {
    let variables = {
      sort: ['createdAt:desc'],
      pagination: {
        pageSize: 40,
      },
      filters: {
        match: {
          id: {
            eq: id,
          },
        },
      },
    };

    const { data } = await client.query({
      query: CommentGQL.GET_COMMENTS,
      variables: {
        ...variables,
      },
    });
    if (data) {
      addComment(data.comments.data).then(() => {
        // setTimeout(() => {
        //   var objDiv = document.getElementById(`comment${mid}`);
        //   objDiv.scrollTop = objDiv.scrollHeight;
        // }, 200);
      });
    }
  };

  const addComment = async (comment) => {
    setComments((c) => {
      return [...comment];
    });
  };

  const comment = async (e) => {
    if (e.target.comment.value.trim() === '') {
      return;
    }
    if (!user) {
      if (sport === 'football') {
        router.push(`/auth/login?redirect=/matching/${id}?linkId=${linkId}`);
      }
      if (sport === 'boxing') {
        router.push(`/auth/login?redirect=/boxing/${id}`);
      }
      return;
    }

    const { data } = await client.mutate({
      mutation: CommentGQL.CREATE_COMMENT,
      variables: {
        data: {
          match: id,
          comment: e.target.comment.value,
          user: user.id,
          publishedAt: new Date(),
        },
      },
    });
    if (data) {
      _createCommet(data.createComment.data);
      e.target.comment.value = '';
    }
  };

  function sort_by_id() {
    return function (elem1, elem2) {
      if (elem1.id < elem2.id) {
        return -1;
      } else if (elem1.id > elem2.id) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  return (
    <div
      className="flex flex-col justify-between border border-gray-700 rounded bg-[#0F172A] pb-2"
      style={{ height: HC + 'px' }}
    >
      <div
        className="overflow-y-auto scrollComment scroll-smooth w-full md:pb-2"
        id={`comment${mid}`}
        style={{ height: HC + 'px' }}
      >
        <ScrollToBottom className="w-full h-full">
          <div className="p-2">
            {comments.sort(sort_by_id()).map((c) => (
              <div className="flex" key={c.id}>
                <div>
                  <p
                    className="mr-1 mt-1 px-1 khFont font-bold max-w-[100px] truncate text-[#00AAFF]"
                    style={{
                      color: c.attributes.user.data.attributes.color,
                    }}
                  >
                    {c.attributes.user.data.attributes.username}
                  </p>
                </div>
                <p
                  className="p-1 px-3 text-gray-400 rounded-lg text-sm khFont text-base bg-[#1E293B] mb-1"
                  style={{
                    overflowWrap: 'anywhere',
                  }}
                >
                  {c.attributes.comment}
                </p>
                {/* <div className="mb-2">
                  <span
                    className="khFont font-bold truncate"
                    style={{
                      color: c.attributes.user.data.attributes.color,
                      maxWidth: 100,
                    }}
                  >
                    {c.attributes.user.data.attributes.username}
                  </span>
                  <span
                    className="px-2 rounded-lgkhFont text-base text-gray-400"
                    style={{
                      overflowWrap: 'anywhere',
                    }}
                  >
                    {c.attributes.comment}
                  </span>
                </div> */}
              </div>
            ))}
          </div>
        </ScrollToBottom>
      </div>
      <div className="px-2">
        {/* {user ? ( */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            comment(e);
          }}
        >
          <label className="block">
            <div className="flex items-center">
              <input
                id="comment"
                name="identifier"
                type="text"
                required
                autoComplete="off"
                className="bg-[#1E293B] rounded-md text-[#00AAFF] placeholder-gray-500 relative block w-full appearance-none border border-gray-600 px-3 py-2 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Type comment..."
              />
              <button type="submit">
                <IoSendSharp
                  className="ml-3 text-sky-600 hover:text-sky-500 cursor-pointer drop-shadow-lg"
                  size={30}
                />
              </button>
            </div>
          </label>
        </form>
        {/* ) : (
          <div className="flex mt-[11px]">
            <button
              onClick={() =>
                router.push(
                  `/auth/login?redirect=/matching/${mid}?linkId=${linkId}`
                )
              }
              className="w-full p-1 bg-sky-600 hover:bg-sky-500 rounded mr-1"
            >
              Login
            </button>
            <button
              onClick={() =>
                router.push(
                  `/auth/register?redirect=/matching/${mid}?linkId=${linkId}`
                )
              }
              className="w-full p-1 bg-green-600 hover:bg-green-500 rounded"
            >
              Register
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Comment;
