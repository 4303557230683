import GraphQL_API from './client';
import * as LeagueGQL from './gql/league.js';
import * as MatchGQL from './gql/match.js';
import * as CommentGQL from './gql/comment.js';
import * as UserGQL from './gql/user.js';
import * as NewsGQL from './gql/news.js';
import * as TeamGQL from './gql/team.js';
import * as CountryGQL from './gql/country.js';
import * as BoxingGQL from './gql/boxing.js';
import * as ImgSliderGQL from './gql/imgSlider.js';

export {
  LeagueGQL,
  MatchGQL,
  CommentGQL,
  UserGQL,
  NewsGQL,
  TeamGQL,
  CountryGQL,
  BoxingGQL,
  ImgSliderGQL,
};
export default GraphQL_API;
