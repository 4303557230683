import React, { useEffect, useState } from 'react';
import Editor1 from './com/Editor1';
import AdminLayout from './com/AdminLayout';
import { useHistory, useParams } from 'react-router-dom';
import { UseStateContext } from '../context/StateContext';
import GraphQL_API, { NewsGQL } from '../graphql';
import axios from 'axios';

const CreateNews = () => {
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const [thumbnail, setThumbnail] = useState('/banner/wing288logo.png');
  const [preview, setPreview] = useState();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const { user } = UseStateContext();
  const params = useParams();
  const nid = params.nid || null;

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (!nid) {
      return;
    }
    fetchData(nid);
  }, [nid]);

  const fetchData = async (id) => {
    try {
      const { data } = await client.query({
        query: NewsGQL.GET_SPORTNEWS_BY_ID,
        variables: {
          id: id,
        },
      });
      const n = data.sportNews.data.attributes;
      setContent(n.content);
      setThumbnail(n.thumnail);
      setTitle(n.title);
    } catch (error) {
      router.push('/admin/news');
    }
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const createNews = async () => {
    let image = thumbnail;
    if (selectedFile) {
      image = await uploadImg(selectedFile);
    }
    try {
      const { data } = await client.mutate({
        mutation: NewsGQL.CREATE_NEWS,
        variables: {
          data: {
            title: title,
            thumnail: image,
            users_permissions_user: user.id,
            content: content,
            publishedAt: new Date(),
          },
        },
      });
      if (data) {
        router.push('/admin/news');
      }
    } catch (error) {
      //   console.log(error?.graphQLErrors[0]?.extensions?.error);
      console.log(error);
    }
  };

  const updateNews = async () => {
    let image = thumbnail;
    if (selectedFile) {
      image = await uploadImg(selectedFile);
    }
    try {
      const { data } = await client.mutate({
        mutation: NewsGQL.UPDATE_NEWS,
        variables: {
          id: nid,
          data: {
            title: title,
            thumnail: image,
            content: content,
          },
        },
      });
      if (data) {
        router.push('/admin/news');
      }
    } catch (error) {
      //   console.log(error?.graphQLErrors[0]?.extensions?.error);
      console.log(error);
    }
  };

  const uploadImg = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { data } = await axios.post(`/api/uploads`, formData, config);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return thumbnail;
    }
  };
  return (
    <AdminLayout>
      <div className="max-w-[700px] mx-auto mt-2">
        <p>ចំណងជើង</p>
        <input
          type="text"
          className="bg-[#1E293B] p-2 w-full rounded"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <p className="mt-4">រូបតំណាង</p>
        <img className="w-full" src={preview ?? thumbnail} alt="" />
        <input
          className="mt-2"
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={onSelectFile}
        />
        <p className="mt-4">អត្ថបទ</p>
        {/* <div className="ck-content">{ParseHTML(content)}</div> */}
        <Editor1
          className="konFont"
          data={content}
          handleChange={(e) => {
            setContent(e);
          }}
        />
        <div className="mt-2">
          {nid ? (
            <button
              className="bg-[#d1bd4d] hover:bg-[#e5cf54] text-black px-4 py-1 font-bold rounded mr-1"
              onClick={updateNews}
            >
              រក្សាទុកការកែប្រែ
            </button>
          ) : (
            <button
              className="bg-[#00AAFF] hover:bg-sky-400 text-black px-4 py-1 font-bold rounded mr-1"
              onClick={createNews}
            >
              បង្កើត
            </button>
          )}
          <button
            className="border text-slate-200 hover:bg-slate-200 hover:text-black  px-4 py-1 font-bold rounded"
            onClick={() => router.push('/admin/news')}
          >
            បោះបង់
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CreateNews;
