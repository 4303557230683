import React from 'react';
import Nav from './Nav';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const location = useLocation();
  const query = qs.parse(location.search);
  const nav = query.nav === 'off' ? false : true;
  return (
    <>
      {nav && <Nav />}
      <div className="px-3">{children}</div>
      <div className="pb-5"></div>
    </>
  );
};

export default Layout;
