import { useHistory } from 'react-router-dom';

const NewsCom = ({ news }) => {
  const router = useHistory();
  return (
    <div className="pb-4">
      <div
        onClick={() => router.push(`/news/${news.id}`)}
        className="bg-[#1E293B] rounded overflow-hidden drop-shadow-xl cursor-pointer"
      >
        <img className="w-full" src={news.attributes.thumnail} alt="" />
        <div className="text-start p-2 khFont">
          <p className="font-[600]">{news.attributes.title}</p>
          <div className="mt-2 flex justify-between items-center">
            <div className="flex justify-center items-center">
              <img className="w-11 h-11" src="/user2.png" alt="" />
              <div className="ml-2">
                <p className="text-[#00AAFF] font-bold">
                  {
                    news.attributes.users_permissions_user.data.attributes
                      .username
                  }
                </p>
                <div className="opacity-50 text-xs">
                  <span className="mr-3 text-gray-300">
                    {new Date(news.attributes.createdAt).toDateString()}
                  </span>
                  <span className="text-xs">
                    {new Date(news.attributes.createdAt).getHours()}:
                    {new Date(news.attributes.createdAt).getMinutes().toString()
                      .length > 1
                      ? new Date(news.attributes.createdAt).getMinutes()
                      : `0${new Date(news.attributes.createdAt).getMinutes()}`}
                  </span>
                </div>
              </div>
            </div>
            <button className="text-sm px-2 py-1 rounded hover:bg-sky-600 hover:text-white text-[#00AAFF] font-bold">
              ចូលមើល
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCom;
