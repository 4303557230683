import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UseStateContext } from '../context/StateContext';
import GraphQL_API, { BoxingGQL } from '../graphql';
import AdminLayout from './com/AdminLayout';
import axios from 'axios';
import uuid from 'react-uuid';
import { MdAutorenew } from 'react-icons/md';
import { RxClipboardCopy } from 'react-icons/rx';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CreateBoxing = () => {
  const client = GraphQL_API({ ctx: null });
  const router = useHistory();
  const [thumbnail, setThumbnail] = useState('/banner/wing288logo.png');
  const [preview, setPreview] = useState();
  const [title, setTitle] = useState('');
  const [post_type, setPost_type] = useState('live');
  const [isLive, setIsLive] = useState(true);
  const [link, setLink] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const { user } = UseStateContext();
  const params = useParams();
  const bid = params.bid || null;

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (!bid) {
      return;
    }
    fetchData(bid);
  }, [bid]);

  const fetchData = async (id) => {
    try {
      const { data } = await client.query({
        query: BoxingGQL.GET_BOXING_BY_ID,
        variables: {
          id: id,
        },
      });
      const b = data.boxing.data.attributes;
      setTitle(b.title);
      setPost_type(b.post_type);
      setIsLive(b.isLive);
      setThumbnail(b.thumbnail);
      setLink(b.link);
    } catch (error) {
      router.push('/admin/boxing');
    }
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const createBoxing = async () => {
    let image = thumbnail;
    if (selectedFile) {
      image = await uploadImg(selectedFile);
    }
    try {
      const { data } = await client.mutate({
        mutation: BoxingGQL.CREATE_BOXING,
        variables: {
          data: {
            title: title,
            thumbnail: image,
            admin: user.id,
            post_type: post_type,
            isLive: isLive === 'true' ? true : false,
            link: link,
            publishedAt: new Date(),
          },
        },
      });
      if (data) {
        router.push('/admin/boxing');
      }
    } catch (error) {
      //   console.log(error?.graphQLErrors[0]?.extensions?.error);
      console.log(error);
    }
  };

  const updateBoxing = async () => {
    let image = thumbnail;
    if (selectedFile) {
      image = await uploadImg(selectedFile);
    }
    try {
      const { data } = await client.mutate({
        mutation: BoxingGQL.UPDATE_BOXING,
        variables: {
          id: bid,
          data: {
            title: title,
            thumbnail: image,
            post_type: post_type,
            isLive: isLive === 'true' ? true : false,
            link: link,
          },
        },
      });
      if (data) {
        router.push('/admin/boxing');
      }
    } catch (error) {
      //   console.log(error?.graphQLErrors[0]?.extensions?.error);
      console.log(error);
    }
  };

  const uploadImg = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { data } = await axios.post(`/api/uploads`, formData, config);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return thumbnail;
    }
  };

  return (
    <AdminLayout>
      <div className="sticky top-[57px] bg-[#0F172A] p-2 flex justify-between z-10 shadow">
        <p className="font-bold">👊 បង្កើតកីឡាប្រដាល់</p>
        <div className="flex">
          <button
            onClick={() => {
              if (bid) {
                updateBoxing();
              } else {
                createBoxing();
              }
            }}
            className="bg-[#00AAFF] hover:bg-sky-400 text-black font-bold p-1 px-4 mx-1"
          >
            រក្សាទុក
          </button>
          <button
            onClick={() => router.push('/admin/boxing')}
            className="opacity-60 hover:opacity-100 bg-white text-black font-bold p-1 px-4 mx-1"
          >
            បោះបង់
          </button>
        </div>
      </div>
      <div className="p-2">
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">
          <div>
            <div className="mb-7">
              <p className="font-bold text-[#00AAFF]">ប្រភេទផុស</p>
              <select
                className="w-full bg-[#0D1426] p-2 border border-gray-700 rounded focus:outline-none"
                name="post_type"
                value={post_type}
                onChange={(e) => {
                  if (e.target.value === 'live') {
                    setLink(`${uuid()}-${new Date().getTime()}`);
                  }
                  if (e.target.value === 'iframe') {
                    setLink(``);
                  }
                  setPost_type(e.target.value);
                }}
              >
                <option value="live">ផ្សាយបន្ដផ្ទាល់</option>
                <option value="iframe">លីង</option>
              </select>
            </div>
            <div>
              <p className="font-bold text-[#00AAFF]">កំពុងផ្សាយផ្ទាល់?</p>
              <select
                className="w-full bg-[#0D1426] p-2 border border-gray-700 rounded focus:outline-none"
                name="isLive"
                value={isLive}
                onChange={(e) => setIsLive(e.target.value)}
              >
                <option value={'true'}>កំពុងផ្សាយផ្ទាល់</option>
                <option value={'false'}>បញ្ចប់ការផ្សាយផ្ទាល់</option>
              </select>
            </div>
          </div>
          <div>
            <p className="font-bold text-[#00AAFF]">ចំណងជើង</p>
            <textarea
              className="w-full bg-[#0D1426] p-2 border border-gray-700 rounded focus:outline-none"
              name="title"
              rows="5"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></textarea>
          </div>
          <div>
            {post_type === 'live' && (
              <div>
                <div className="mb-7">
                  <div className="flex justify-between">
                    <p className="font-bold text-[#00AAFF]">URL</p>
                    <CopyToClipboard
                      text={`rtmp://jrnn21test02.learnwith21.com/live`}
                    >
                      <RxClipboardCopy
                        title="copy"
                        size={18}
                        className="text-[#00AAFF] cursor-pointer hover:text-white"
                      />
                    </CopyToClipboard>
                  </div>
                  <input
                    type="text"
                    readOnly
                    value={'rtmp://jrnn21test02.learnwith21.com/live'}
                    className="w-full bg-[#0D1426] p-2 border border-gray-700 rounded focus:outline-none"
                  />
                </div>
                <div>
                  <div className="flex justify-between">
                    <p className="font-bold text-[#00AAFF]">Stream key</p>
                    <div className="flex items-center">
                      <CopyToClipboard text={link}>
                        <RxClipboardCopy
                          title="copy"
                          size={18}
                          className="text-[#00AAFF] cursor-pointer hover:text-white mr-2"
                        />
                      </CopyToClipboard>
                      <MdAutorenew
                        className="text-[#00AAFF] cursor-pointer hover:text-white"
                        size={22}
                        onClick={() =>
                          setLink(`${uuid()}-${new Date().getTime()}`)
                        }
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    readOnly
                    value={link}
                    className="w-full bg-[#0D1426] p-2 border border-gray-700 rounded focus:outline-none"
                  />
                </div>
              </div>
            )}
            {post_type === 'iframe' && (
              <div>
                <div className="flex justify-between">
                  <p className="font-bold text-[#00AAFF]">លីង</p>
                  <div>
                    {post_type === 'live' && (
                      <MdAutorenew
                        className="text-[#00AAFF] cursor-pointer hover:text-white"
                        size={22}
                        onClick={() =>
                          setLink(`${uuid()}-${new Date().getTime()}`)
                        }
                      />
                    )}
                  </div>
                </div>
                <textarea
                  className="w-full bg-[#0D1426] p-2 border border-gray-700 rounded focus:outline-none"
                  name="link"
                  rows="5"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                ></textarea>
              </div>
            )}
          </div>
          <div>
            <div className="flex justify-between">
              <p className="font-bold text-[#00AAFF]">រូបតំណាង</p>
              <input
                className="w-[105px]"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={onSelectFile}
              />
            </div>
            <div className="p-2">
              <img
                className="max-w-[300px] object-cover rounded mx-auto"
                src={preview ?? thumbnail}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CreateBoxing;
