import React from 'react';
import GroupTeam from './GroupTeam';

const Round16 = ({ Round: team }) => {
  return (
    <div className="w-full overflow-x-auto w22Scroll">
      <div className="relative rounded mt-2 w-[750px] h-[630px]">
        <div className="grid grid-cols-4 gap-10 w-[750px] text-center text-white font-[500] text-sm">
          <p>Round of 16</p>
          <p>Round of 8</p>
          <p>Semi-finals</p>
          <p>Final</p>
        </div>
        <div className="16">
          <GroupTeam place={'16-1'} team={team} t={40} />
          <GroupTeam place={'16-2'} team={team} t={65} />
          <GroupTeam place={'16-3'} team={team} t={115} />
          <GroupTeam place={'16-4'} team={team} t={140} />
          <GroupTeam place={'16-5'} team={team} t={190} />
          <GroupTeam place={'16-6'} team={team} t={215} />
          <GroupTeam place={'16-7'} team={team} t={265} />
          <GroupTeam place={'16-8'} team={team} t={290} />
          <GroupTeam place={'16-9'} team={team} t={340} />
          <GroupTeam place={'16-10'} team={team} t={365} />
          <GroupTeam place={'16-11'} team={team} t={415} />
          <GroupTeam place={'16-12'} team={team} t={440} />
          <GroupTeam place={'16-13'} team={team} t={490} />
          <GroupTeam place={'16-14'} team={team} t={515} />
          <GroupTeam place={'16-15'} team={team} t={565} />
          <GroupTeam place={'16-16'} team={team} t={590} />
        </div>
        <div className="8">
          <GroupTeam place={'8-1'} team={team} t={75} l={200} />
          <GroupTeam place={'8-2'} team={team} t={100} l={200} />
          <GroupTeam place={'8-3'} team={team} t={225} l={200} />
          <GroupTeam place={'8-4'} team={team} t={250} l={200} />
          <GroupTeam place={'8-5'} team={team} t={375} l={200} />
          <GroupTeam place={'8-6'} team={team} t={400} l={200} />
          <GroupTeam place={'8-7'} team={team} t={525} l={200} />
          <GroupTeam place={'8-8'} team={team} t={550} l={200} />
        </div>
        <div className="4-L">
          <GroupTeam place={'4-1'} team={team} t={150} l={400} />
          <GroupTeam place={'4-2'} team={team} t={175} l={400} />
          <GroupTeam place={'4-3'} team={team} t={445} l={400} />
          <GroupTeam place={'4-4'} team={team} t={470} l={400} />
        </div>
        <div className="F">
          <GroupTeam place={'2-1'} team={team} t={300} l={600} />
          <GroupTeam place={'2-2'} team={team} t={325} l={600} />
        </div>
      </div>
    </div>
  );
};

export default Round16;
