import { gql } from '@apollo/client';

export const CREATE_NEWS = gql`
  mutation createSportNews($data: SportNewsInput!) {
    createSportNews(data: $data) {
      data {
        id
        attributes {
          title
          thumnail
        }
      }
    }
  }
`;

export const UPDATE_NEWS = gql`
  mutation updateSportNews($id: ID!, $data: SportNewsInput!) {
    updateSportNews(id: $id, data: $data) {
      data {
        id
        attributes {
          title
          thumnail
        }
      }
    }
  }
`;

export const DELETE_NEWS = gql`
  mutation deleteSportNews($id: ID!) {
    deleteSportNews(id: $id) {
      data {
        id
      }
    }
  }
`;

export const GET_SPORTNEWSS = gql`
  query sportNewss($pagination: PaginationArg, $sort: [String]) {
    sportNewss(pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          title
          thumnail
          content
          users_permissions_user {
            data {
              id
              attributes {
                username
              }
            }
          }
          createdAt
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

export const GET_SPORTNEWS_BY_ID = gql`
  query sportNews($id: ID) {
    sportNews(id: $id) {
      data {
        id
        attributes {
          title
          thumnail
          content
          users_permissions_user {
            data {
              id
              attributes {
                username
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;
