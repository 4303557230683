import React, { useEffect, useState } from 'react';
import NewsCom from '../components/NewsCom';
import GraphQL_API, { NewsGQL } from '../graphql';

const SportNews = () => {
  const client = GraphQL_API({ ctx: null });
  const [newss, setNewss] = useState([]);
  const [meta, setMeta] = useState();

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);
  const fetchData = async ({ page = 1 }) => {
    const { data } = await client.query({
      query: NewsGQL.GET_SPORTNEWSS,
      variables: {
        sort: ['createdAt:desc'],
        pagination: {
          page: page,
          pageSize: 10,
        },
      },
    });
    if (data) {
      setMeta(data.sportNewss.meta);
      if (page === 1) {
        setNewss(data.sportNewss.data);
        return;
      }
      setNewss((n) => {
        return [...n, ...data.sportNewss.data];
      });
    }
  };
  return (
    <div className="max-w-[750px] text-gray-300 mx-auto mt-3 mb-5">
      {newss.map((n) => (
        <React.Fragment key={n.id}>
          <NewsCom news={n} />
        </React.Fragment>
      ))}
      <div className="text-center">
        {meta && meta.pagination.page < meta.pagination.pageCount && (
          <button
            className="px-4 py-1 drop-shadow-xl bg-[#1E293B] hover:bg-slate-300 hover:text-black rounded"
            onClick={() => fetchData({ page: meta.pagination.page + 1 })}
          >
            មានទៀត
          </button>
        )}
      </div>
    </div>
  );
};

export default SportNews;
