import React from 'react';

const SideBanner = () => {
  return (
    <>
      <div className="p-1 pb-4 bg-[#1E293B] rounded-md">
        <img
          className="py-3 cursor-pointer"
          src="/banner/wing288logo.png"
          onClick={() => window.open('https://m.wing288.me/?fid=WINGTYAA')}
          alt=""
        />
        <div className="font-[400] px-1 text-center">
          Wing288 ជាវេបសាយកម្សាន្ដអនឡាញដែលមានកីឡា 💫ចុះឈ្មោះ-ដំបូងទទួលទឹកប្រាក់
          111$ ដុល្លារ និង ☆ ទឹកប្រាក់បង្វិលជូន រហូតដល់ 2021$ ☆ បន្ថែម 28%
          លើការលេងបាការ៉ាត់ និង កេណូ។ 📲 ទនាក់ទំនងចុះឈ្មោះតារយ: Inbox ☎️
          <span className="text-rose-600">087372288</span>,{' '}
          <span className="text-rose-600">012372288</span>,{' '}
          <span className="text-rose-600">090372288</span>{' '}
          <p
            className="underline text-[#00AAFF] cursor-pointer opacity-75 hover:opacity-100"
            onClick={() => window.open('https://m.wing288.me/?fid=WINGTYAA')}
          >
            👉លីងចុះឈ្មោះខ្លួនឯងជាមួយ wing288
          </p>
        </div>
      </div>
    </>
  );
};

export default SideBanner;
